import { useTranslation } from "react-i18next";
import { Drawer, Select, Table } from "antd";
import { Row, Col, Form, Input, Switch, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { fetchGroupForm, fetchServicesChosen, fetchOptionsServices, editGroupForm, fetchBudgetsGroupForm, fetchBudgetsGroupFormById } from "../../../../api/repositoryEP";
import { Button } from "antd";
import type { SelectProps, TableProps } from 'antd';
import { BudgetData, EditGroupFormDto, FormGroupData, ServiceData } from "../../../../models/ConfigurationData";
import { DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import './GroupForm.scss';
import BudgetForm from "./BudgetForm/BudgetForm";
import ModalGeneric from "../MappingConfig/TabMapping/Modals/ModalGeneric";
import { LoadingData } from "../../../common";

interface GroupFormProps {
    defaultLanguage: string
    languages: string[];
}

const GroupForm = (props: GroupFormProps): JSX.Element => {

    const { t } = useTranslation();
    const languages = props.languages;
    const [formGroupData, setFormGroupData] = useState<FormGroupData>({} as FormGroupData);
    const [language, setLanguage] = useState(props.defaultLanguage);
    const [languageService, setLanguageService] = useState(props.defaultLanguage);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    const [services, setServices] = useState<string[]>([]);
    const [options, setOptions] = useState<SelectProps['options']>([]);
    const [form] = Form.useForm();
    const [tableData, setTableData] = useState<BudgetData[]>([]);
    const [isEditDrawerVisible, setIsEditDrawerVisible] = useState<boolean>(false);
    const [isNewDrawerVisible, setIsNewDrawerVisible] = useState<boolean>(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [budgetData, setBudgetData] = useState<BudgetData[] | null>(null); // el selecionado para editar o borrar
    const [idSelected, setIdSelected] = useState<string>("");
    const drawerWidth = window.innerWidth <= 768 ? '100%' : '40%';
    const [searching, setSearching] = useState<boolean>(false);



    const handleServicesChosenChange = (values: any) => {
        setServices(values);
    }

    const handleLanguage = (language: any) => {
        {
            setLanguageService(language);
            form.setFieldValue('language', language);
        }
    }

    const editBudget = (budget: BudgetData) => {
        if (budget != undefined) {
            fetchBudgetsGroupFormById(budget.id).then((r) => {
                if (r?.type === 1) {
                    setBudgetData(r.data);
                    setIsEditDrawerVisible(true);
                }

            });
        }
    }
    const deleteBudget = (budget: BudgetData) => {
        const d: BudgetData[] = [budget];
        setBudgetData(d);
        setDeleteModalVisible(true);
    }
    const addBudget = () => {
        setBudgetData(null);
        setIsNewDrawerVisible(true);
    }


    const columns: TableProps<BudgetData>['columns'] = [
        {
            title: t("components.tab-configuration.group-form.fields.table-name"),
            dataIndex: 'name',
            key: 'name',
            render: (name: string) => name,
        },
        {
            title: t("components.tab-configuration.group-form.fields.table-actions"),
            key: 'action',
            render: (_, record: BudgetData) => (
                <div className='app-icon-table-container' >
                    <EditOutlined className='app-icon-table' onClick={() => editBudget(record)} />
                    <DeleteOutlined className='app-icon-table' onClick={() => deleteBudget(record)} />
                </div>
            ),
        }
    ];

    const fetchServicesAndOptions = (language: string) => {
        const fetchOptionsServicesPromise = fetchOptionsServices(language).then((r) => {
            if (r?.type === 1) {
                const newOptions = (r.data as Array<ServiceData>).map(service => ({
                    label: service.name,
                    value: service.id,
                }));
                setOptions(newOptions);
            }
        });

        const fetchServicesChosenPromise = fetchServicesChosen(language).then((r) => {
            if (r?.type === 1) {
                const servicesChosen = (r.data as Array<ServiceData>).map(service => service.id);
                setServices(servicesChosen);
                form.setFieldValue('services', servicesChosen);
            }
        });

        return Promise.all([fetchOptionsServicesPromise, fetchServicesChosenPromise]);
    };

    // Carga inicial
    useEffect(() => {
        setLoading(true);
        form.setFieldValue('language', languageService);

        const fetchBudgetsGroupFormPromise = fetchBudgetsGroupForm(languageService).then((r) => {
            if (r?.type === 1) {
                setBudgetData(Array.isArray(r.data) ? r.data : []);
                setTableData(r.data as BudgetData[]);
            }
            setLoadingTable(false);
        });

        const fetchGroupFormPromise = fetchGroupForm(props.defaultLanguage).then((r) => {setFormGroupData(r?.data);});

        Promise.all([fetchServicesAndOptions(languageService), fetchBudgetsGroupFormPromise,fetchGroupFormPromise])
            .then(() => {
                setLoading(false);
            })


    }, []); 

    // Carga al cambiar el idioma
    useEffect(() => {
        form.setFieldValue('language', languageService);
        fetchServicesAndOptions(languageService)


    }, [languageService]);


    const onFinish = (values: any) => {
        const serviceLabels = values.services.map((id: string) => id);
        setSearching(true)
        // Crear el DTO con los servicios mapeados
        const editGroupFormDto: EditGroupFormDto = {
            id: formGroupData.id,
            email: values.email,
            agentCopy: values.agentCopy,
            minOccupancy: values['min-occupancy'],
            maxOccupancy: values['max-occupancy'],
            minRooms: values['min-rooms'],
            maxRooms: values['max-rooms'],
            release: values.release,
            language: values.language,
            services: serviceLabels,
        };
        editGroupForm(editGroupFormDto).then((r) => {
            if (r?.type === 1) {
                const scrollTopElement = document.getElementById("content-page")!;
                scrollTopElement.scrollTop = 0;
            }
            setSearching(false)
        });
    }

    return (
        <div className="group-form-component">
            {loading ? (<LoadingData />) : (
                <div>
                    {deleteModalVisible && budgetData != undefined && budgetData?.length >= 0 &&
                        <ModalGeneric
                            modalVisible={deleteModalVisible}
                            setModalVisible={setDeleteModalVisible}
                            id={idSelected}
                            action='deleteBudget'
                            data={budgetData}
                            setData={setBudgetData}
                        />

                    }
                    <Drawer
                        open={isEditDrawerVisible}
                        title={t("components.tab-configuration.group-form.fields.budget-edit")}
                        onClose={() => {
                            setBudgetData(null)
                            setIsEditDrawerVisible(false)
                        }}
                        width={drawerWidth}
                        closable>
                        <BudgetForm
                            setDrawerVisible={setIsEditDrawerVisible}
                            drawerVisible={isEditDrawerVisible}
                            budgetData={budgetData}
                            setBudgetData={setBudgetData}
                        />
                    </Drawer>


                    <Drawer
                        open={isNewDrawerVisible}
                        title={t("components.tab-configuration.group-form.fields.budget-new")}
                        onClose={() => {
                            setBudgetData(null)
                            setIsNewDrawerVisible(false)
                        }}
                        width={drawerWidth}
                        closable>
                        <BudgetForm
                            setDrawerVisible={setIsNewDrawerVisible}
                            drawerVisible={isNewDrawerVisible}
                            budgetData={budgetData}
                            setBudgetData={setBudgetData}
                        />
                    </Drawer>
                    <Form layout="vertical" onFinish={onFinish} form={form}>
                        <Row gutter={4} style={{ flexDirection: 'column' }}>
                            <Col xs={24}>
                                <p className="app-title">
                                    {t("components.tab-configuration.group-form.email-config")}
                                </p>

                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="email"
                                    extra={
                                        <small className="special-extra-info">
                                            <span>{t("components.tab-configuration.group-form.fields.agent-copy")}</span>
                                            <Form.Item
                                                name="agent-copy"
                                                valuePropName="checked"
                                                initialValue={formGroupData.sendCopyToAgent}
                                            >
                                                <Switch size="small" className="app-switch" />
                                            </Form.Item>
                                        </small>
                                    }
                                    label={t("components.tab-configuration.group-form.fields.email")}
                                    initialValue={formGroupData.mailTo}

                                >
                                    <Input className="app-input" />
                                </Form.Item>

                            </Col>
                            <Col xs={24} md={8} lg={5}>
                                <Button className="app-button" htmlType='submit' disabled={searching}>
                                    {searching ? <LoadingOutlined /> : ''}
                                    {t("components.button.save")}
                                </Button>
                            </Col>

                            <Row gutter={4} style={{ flexDirection: 'row' }}>
                                <Col xs={24}>
                                    <p className="app-title">
                                        {t("components.tab-configuration.group-form.stay-config")}
                                    </p>
                                </Col>
                                <Col xs={24} md={12} xxl={4}>
                                    <Form.Item
                                        name="release"
                                        label={t("components.tab-configuration.group-form.fields.release")}
                                        initialValue={formGroupData.release}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                                <Col xs={0} md={12} xxl={0}></Col>
                                <Col xs={24} md={12}xxl={5}>
                                    <Form.Item
                                        name="min-occupancy"
                                        label={t("components.tab-configuration.group-form.fields.min-occupancy")}
                                        initialValue={formGroupData.minOccupancyNumber}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xxl={5}>
                                    <Form.Item
                                        name="max-occupancy"
                                        label={t("components.tab-configuration.group-form.fields.max-occupancy")}
                                        initialValue={formGroupData.maxOccupancyNumber}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xxl={5}>
                                    <Form.Item
                                        name="min-rooms"
                                        label={t("components.tab-configuration.group-form.fields.min-rooms")}
                                        initialValue={formGroupData.minRoomsNumber}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12} xxl={5}>
                                    <Form.Item
                                        name="max-rooms"
                                        label={t("components.tab-configuration.group-form.fields.max-rooms")}
                                        initialValue={formGroupData.maxRoomsNumber}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                               

                                <Col xs={24} md={4} lg={5}>
                                    <Button className="app-button" htmlType='submit' disabled={searching}>
                                        {searching ? <LoadingOutlined /> : ''}
                                        {t("components.button.save")}
                                    </Button>
                                </Col>
                            </Row>

                            <Row gutter={4} style={{ flexDirection: 'row' }}>
                                <Col xs={24} sm={4}>
                                </Col>
                                <Col xs={24} md={24}>
                                    <p className="app-title">
                                        {t("components.tab-configuration.group-form.fields.table-title")}
                                    </p>
                                    <Button className='app-button short-button float-button' onClick={() => addBudget()}> + {t("components.button.add")}</Button>
                                </Col>


                                <Col xs={24} md={24}>
                                    <Table
                                        className='table'
                                        columns={columns}
                                        dataSource={tableData}
                                        rowKey="id"
                                        scroll={{ x: 'max-content' }}
                                        loading={loadingTable} />
                                </Col>

                                <Col xs={24} md={6}  >
                                    <Form.Item
                                        name="language"
                                        label={t("components.tab-configuration.group-form.fields.services")}

                                    >
                                        <Select defaultValue={languageService} onChange={handleLanguage}>
                                            {languages.map(language => (
                                                <Select.Option key={language} value={language}>
                                                    {t(`languages.${language}`)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>


                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="services"
                                        label=" "
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            placeholder={t("components.tab-configuration.group-form.fields.services-placeholder")}
                                            options={options}
                                            value={services}
                                            onChange={handleServicesChosenChange}
                                        >
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={4}>
                                    <Button className="app-button" htmlType='submit' disabled={searching}>
                                        {searching ? <LoadingOutlined /> : ''}
                                        {t("components.button.save")}
                                    </Button>
                                </Col>
                            </Row>


                        </Row>
                    </Form>
                </div>
            )
            }
        </div>
    );
};



export default GroupForm;