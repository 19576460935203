import './NewsDetailPage.scss';
import { useTranslation } from 'react-i18next';
import { Avatar, Col, Row, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NewsData } from '../../../models/CommunicationsData';
import { GetNewsData } from '../../../api/repositoryEP';
import { LoadingData } from '../../../components/common';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import LanguageSelectorNews from '../../../components/common/LanguageSelectorNews/LanguageSelectorNews';
import { URL_IMAGE } from '../../../utils/constants';
import TagStatusNews from '../../../components/news/TagStatusNews';
import moment from 'moment';


const NewsDetailPage = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [newsInfo, setNewsInfo] = useState<NewsData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>("");


    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            GetNewsData(id).then((r) => {
                if (r?.type === 1) {
                    console.log(r.data);
                    const data = r.data as NewsData;
                    setNewsInfo(data);
                    if (data.titlesByLanguage.length > 0) {
                        setSelectedLanguage(data.titlesByLanguage[0].key); // Valor por defecto dinámico
                    }
                }
                setIsLoading(false);
            });
        }
    }, [id]);


    const handleNavigateBack = () => {
        const currentPath = window.location.pathname;
        const newPath = currentPath.replace(`/new-details/${id}`, ""); // Elimina "news-details/:id"
        navigate(newPath);
    };

    const handleNavigateEdit = () => {
        const currentPath = window.location.pathname;
        const newPath = currentPath.replace(`/new-details/${id}`, `/new/${id}`);
        navigate(newPath);
    };


    const handleLanguageChange = (value: string) => {
        setSelectedLanguage(value);
    };

    return (
        <div className="news-details-page">
            {isLoading && <LoadingData />}
            {!isLoading && newsInfo && (
                <Row gutter={[16, 16]} >
                    <Col xs={24} md={24}>
                        <span
                            className="app-text back-link"
                            onClick={handleNavigateBack}
                        >
                            <ArrowLeftOutlined className="back-button" />
                            {t("button.back-to-news")}
                        </span>
                    </Col>


                    <Col xs={24} md={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <LanguageSelectorNews
                            languages={newsInfo?.titlesByLanguage.map((language) => language.key) || []}
                            selectedLanguage={selectedLanguage}
                            onLanguageChange={handleLanguageChange}
                        />
                    </Col>


                    <Col xs={24} md={12}>
                        <Row>
                            <Col xs={24} md={24} className="container-right">
                                <span>
                                    <TagStatusNews
                                        status={newsInfo?.status || "inactive"}
                                        sheduleDayFrom={newsInfo?.sheduleDayFrom || new Date(Date.now())}
                                    />
                                    <EditOutlined onClick={handleNavigateEdit} className="icon" />
                                </span>
                            </Col>


                            {newsInfo?.status === "active" && newsInfo?.sheduleDayFrom ? (
                                <Col xs={24} md={24} className="container-right-2">
                                    <Typography className="details-text">
                                        {t("components.communications.news-will-be-publish")}{" "}
                                        {moment(newsInfo.sheduleDayFrom).format("DD/MM/YYYY")}
                                    </Typography>
                                </Col>
                            ) : null}
                        </Row>
                    </Col>


                    <Col xs={24} md={24} className="app-title large ">
                        {newsInfo?.titlesByLanguage.find(language => language.key === selectedLanguage)?.value}
                    </Col>

                    <Col xs={24} md={24} className="container">
                        <img

                            className="app-img panoramic"
                            src={
                                newsInfo?.img
                                    ? `${newsInfo?.img}?id=${Math.random()}`
                                    : URL_IMAGE + "no-image.jpg"
                            }
                        />

                    </Col>

                    <Col xs={24} md={24} >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: newsInfo?.descriptionsByLanguage?.find(language => language.key === selectedLanguage)?.value || '',
                            }}
                        />
                    </Col>

                </Row>
            )}
        </div>
    );
};

export default NewsDetailPage;
