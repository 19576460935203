import { Alert, Button, Col, Form, Input, Row, Select } from "antd";

import './EditContractComponent.scss';
import { useTranslation } from "react-i18next";
import { EditContractData } from "../../../models/CreateContract";
import { useEffect, useState } from "react";
import Contract from "../../../models/Contract";

interface EditContractComponentProps {
    contractData: Contract | undefined;
    onFinish: (contractData: EditContractData) => void;
    onCancel: () => void;
    resetFields: number;
    loading: boolean;
}

const EditContractComponent = (props: EditContractComponentProps) : JSX.Element => {
    const { t } = useTranslation();
    
    const [form] = Form.useForm();

    const { onFinish, onCancel, resetFields } = props;

    const [contractData, setContractData] = useState<Contract | undefined>(props.contractData);

    const [loading, setLoading] = useState<boolean>(props.loading);

    useEffect(()=>{
        setLoading(props.loading);
    }
    ,[props.loading]);

    useEffect(()=>{
        setLoading(false);
        setIsActive(props.contractData?.isActive ?? true);
        setIsBaseContract(props.contractData?.isBase ?? false);
        setContractData(props.contractData);

        form.setFieldsValue({
            id: props.contractData?.id,
            name: props.contractData?.name,
            pmsCode: props.contractData?.pmsCode,
            integrationCode: props.contractData?.integrationCode,
            isBaseContract: props.contractData?.isBase,
            isActive: props.contractData?.isActive
        });
    },[props.contractData]);

    useEffect(() => {
        form.resetFields();
    },[resetFields]);

    const onCancelEdit = () : void => {
        form.resetFields();
        onCancel();
    }

    const onEditContract = () : void => {

        if (contractData){
            let contract : EditContractData = {
                id: contractData.id,
                name: form.getFieldValue('name'),
                pmsCode: form.getFieldValue('pmsCode'),
                integrationCode: form.getFieldValue('integrationCode'),
                isBase: isBaseContract,
                isActive: isActive
            }
    
            onFinish(contract);
        }
    }

    const [isActive, setIsActive] = useState<boolean>(true);
    const [isBaseContract, setIsBaseContract] = useState<boolean>(false);

    return (
        <div className="add-contract">
            <Form form={form} layout="vertical" onFinish={onEditContract} >
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            name="id" 
                            hidden
                            initialValue={contractData?.id}
                        >
                            <Input className="app-input" allowClear/>
                        </Form.Item>
                        <Form.Item
                            name="name" 
                            initialValue={contractData?.name}
                            label={t("components.contract-form-add.name")} 
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input className="app-input" allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item 
                            initialValue={contractData?.pmsCode}
                            name="pmsCode" 
                            label={t("components.contract-form-add.pms-code")}
                        >
                            <Input className="app-input" allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item 
                            name="integrationCode" 
                            initialValue={contractData?.integrationCode}
                            label={t("components.contract-form-add.integration-code")} 
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >

                            <Input className="app-input" allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item 
                            name="isBaseContract" 
                            label={t("components.contract-form-add.is-base-contract")} 
                            initialValue={contractData?.isBase}
                        >
                            <Select disabled={contractData?.isBase} onChange={setIsBaseContract} className="app-input" allowClear>
                                <Select.Option value={true}>{t("components.contract-form-add.is-base-contract-yes")}</Select.Option>
                                <Select.Option value={false}>{t("components.contract-form-add.is-base-contract-no")}</Select.Option>                                
                            </Select>
                        </Form.Item>
                        {
                            isBaseContract && <Alert className="alert-base-message" message={t("components.contract-form-add.is-base-contract-message")} type="warning" />
                        }
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="isActive" label={t("components.contract-form-add.is-active")} 
                        initialValue={isBaseContract || contractData?.isActive}>
                        <Select disabled={isBaseContract} onChange={setIsActive} className="app-input" allowClear value={isBaseContract || contractData?.isActive}>
                                <Select.Option value={true}>{t("components.contract-form-add.is-active-yes")}</Select.Option>
                                <Select.Option value={false}>{t("components.contract-form-add.is-active-no")}</Select.Option>                                
                            </Select>
                        </Form.Item>
                        {
                            isBaseContract && <Alert className="alert-base-message" message={t("components.contract-form-add.is-base-contract-then-active-mandatory-message")} type="warning" />
                        }
                    </Col>
                    <div className="actions">
                        <Button className="app-button danger" type="primary" onClick={onCancelEdit}>
                            {t("components.contract-form-add.cancel")}
                        </Button>
                        <Button className="app-button" type="primary" htmlType="submit">
                            {
                                loading ? <div className="app-spinner"></div> : null
                            }
                            {t("components.contract-form-add.save")}
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    )
}

export default EditContractComponent;