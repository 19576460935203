import { Row } from "antd";
import { AccountAsignationData, EditAccountAsignationData, KeyValue } from "../../../models/AccountAsignationData";
import { LoadingData } from "../../common";
import AccountAsignationElement from "../AccountAsignationElement/AccountAsignationElement";

interface AccountAsignationListComponentProps {
    accountAsignationData: AccountAsignationData[];
    contracts: KeyValue[],
    markets: KeyValue[],

    save: (data: EditAccountAsignationData) => void;
    edit: (data: EditAccountAsignationData) => void;
    delete: (id: string) => void;

    isLoading: boolean;

    setSelectedConfiguration: (aad: AccountAsignationData | null) => void;
    setEditDrawerVisible: (isVisible: boolean) => void;
}

const AccountAsignationListComponent = (props: AccountAsignationListComponentProps): JSX.Element => {

    const { accountAsignationData } = props;

    return (
        <div>
            {props.isLoading && <LoadingData />}
            <Row className="commissions-configuration-list">
                {!props.isLoading && (
                    <>
                        {props.accountAsignationData.map((accountAsignation) => (
                            <AccountAsignationElement 
                                configuration={accountAsignation}
                                contracts={props.contracts}
                                markets={props.markets}

                                save={props.save}
                                edit={props.edit}
                                delete={props.delete}
                                setEditDrawerVisible={props.setEditDrawerVisible}
                                setSelectedConfiguration={props.setSelectedConfiguration} // Pasamos esta función

                            />
                        ))}

                    </>
                )}


            </Row>


        </div >
    );
}

export default AccountAsignationListComponent;