import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Input, Row, Select, Drawer } from "antd";
import "./UsersFilterComponent.scss";
import { FileExcelOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";

import NewUserForm from "../../NewUserForm/NewUserForm";
import { PlusOutlined } from '@ant-design/icons';
import { UsersFilterData2 } from "../../../models/UsersFilterData";
import { fetchLanguagesAvailables } from "../../../api/repositoryEP";

interface UsersFilterComponentProps {
  onSearch: (filterData: UsersFilterData2) => void;
  searching: boolean;
}

const UsersFilterComponent = (props: UsersFilterComponentProps): JSX.Element => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const [languagesInWA, setLanguagesInWA] = useState<string[]>([]);

  useEffect(() => {
    fetchLanguagesAvailables("bo").then((response) => {
      if (response?.type === 1) {
        setLanguagesInWA(response.data);
      }
    });
  }, []);

  const items = [
    {
      key: '1',
      label: t("components.account-users.filter.active-users"), // usuarios activos
    },
    {
      key: '2',
      label: t("components.account-users.filter.block-users"), // usuarios bloqueados
    },
    {
      key: '3',
      label: t("components.account-users.filter.admin-users"), // administradores
    }
  ];

  const search = () => {
    const values = form.getFieldsValue();
    const { name = '', email = '', username = '', state = '', role = '', page = 0, pageSize = 10 } = values;
    const filterData: UsersFilterData2 = { name, email, username, state, role, page, pageSize };
    props.onSearch(filterData);
  };

  useEffect(() => {
    if (!drawerVisible) {
      search();
    }
  }, [drawerVisible]);


  const createUser = () => {
    setDrawerVisible(true);
  }


  const onMenuClick = ({ key }: any) => {
    console.log(`Click on item ${key}`);
  };
  const screenWidth = window.innerWidth;
  const drawerWidth = screenWidth <= 768 ? '100%' : '30%';

  return (

    <div className="accounts-filter">
         <div className="export-data-content">
        <Dropdown.Button
          menu={{ items, onClick: onMenuClick }}
          className="export-button-dropdown"
          placement="bottomRight" 
        >
          <FileExcelOutlined className="excel-button" />
          {t("components.accounts.filter.export-data")}
        </Dropdown.Button>

      </div>

      <Drawer open={drawerVisible} title={<span>{t("components.new-users.title")}</span>} onClose={() => { setDrawerVisible(false) }} width={drawerWidth} closable>
        <NewUserForm setDrawerVisible={setDrawerVisible} languagesAvailable={languagesInWA} />
      </Drawer>
   
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="name"
              label={t("components.account-users.filter.name-and-surname")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="email"
              label={t("components.account-users.filter.email")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="username"
              label={t("components.account-users.filter.username")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item name="state" label={t("components.accounts.filter.status")} initialValue={''}>
              <Select className="app-input" defaultValue={""}>
                <Select.Option value="">{t("forms.selects.select-else")}</Select.Option>
                <Select.Option value="active">{t("components.accounts.filter.status-active")}</Select.Option>
                <Select.Option value="inactive">{t("components.accounts.filter.status-inactive")}</Select.Option>
                <Select.Option value="confirm_needed">{t("components.accounts.filter.status-confirm-needed")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item name="role" label={t("components.account-users.filter.role")} initialValue={''}>
              <Select className="app-input" defaultValue={""}>
                <Select.Option value="">{t("forms.selects.select-else")}</Select.Option>
                <Select.Option value="super_admin">{t("roles.super_admin")}</Select.Option>
                <Select.Option value="bo_manager">{t("roles.bo_manager")}</Select.Option>
                <Select.Option value="bo_basic">{t("roles.bo_basic")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item>
              <Button className="app-button" onClick={search} disabled={props.searching}>
                {props.searching ? <LoadingOutlined /> : ''}
                {t("components.account-users.filter.search")}
              </Button>
            </Form.Item>
          </Col>

          <Col xs={24} md={16}>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item style={{ textAlign: "center" }}>
              <Button className="app-button" style={{ width: "100%" }} icon={<PlusOutlined />} onClick={createUser}>
                {t("components.account-users.filter.new-user")}
              </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div>
  );
};

export default UsersFilterComponent;
