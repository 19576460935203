import { Button, Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountAsignationData } from "../../../models/AccountAsignationData";
import { CheckIsValidCommissionConfigurationName } from "../../../api/repositoryEP";

interface AccountAsignationFormProps {
    selectedConfiguration: AccountAsignationData | null;
    setSelectedConfiguration: (aad: AccountAsignationData | null) => void;
    create: (name: string) => void;
    editName: (id: string, name: string) => void;
    isLoading: boolean;
}

const AccountAsignationForm = (props: AccountAsignationFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [disableButton, setDisableButton] = useState<boolean>(true);

    const onFinish = async (values: { name: string }) => {
        try {
            if (props.selectedConfiguration) {
                await props.editName(props.selectedConfiguration.id, values.name);
            } else {
                await props.create(values.name);
            }
        } finally {
            form.setFieldValue("name", ""); // Restablecer el campo después de finalizar
        }
    };

    const checkIsValidName = async (name: string): Promise<void> => {
        // Limpia los errores al comenzar
        setDisableButton(true);
        form.setFields([{ name: "name", errors: [] }]);
    
        // Evita llamar a la API si el nombre está vacío
        if (!name.trim()) {
            return;
        }
    
        // Evita llamar a la API si el nombre es igual al actual
        if (
            props.selectedConfiguration &&
            props.selectedConfiguration.name.trim() === name.trim()
        ) {
         
            return;
        }
    
        try {

            const response = await CheckIsValidCommissionConfigurationName(name);
            const isValid = response?.type === 1 ? response.data : false;
    
            if (!isValid) {
                form.setFields([
                    {
                        name: "name",
                        errors: [t("pages.account-asignation.name-unavailable")],
                    },
                ]);
            } else {
                setDisableButton(false);
            }
        } catch (error) {
            console.error("Error checking name validity:", error);
            form.setFields([
                {
                    name: "name",
                    errors: [t("pages.account-asignation.api-error")],
                },
            ]);
        }
    };
    

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // Limpia los errores del campo "name"
        form.setFields([{ name: "name", errors: [] }]);

        // Continúa con la validación
        checkIsValidName(value);
    };

    useEffect(() => {
        if (props.selectedConfiguration) {
            form.setFieldValue("name", props.selectedConfiguration.name);
        } else {
            form.resetFields();
        }
    }, [props.selectedConfiguration, form]);

    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            initialValues={{ name: "" }}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Form.Item
                        label={t("pages.account-asignation.name")}
                        name="name"
                        rules={[
                            { required: true, message: t("pages.account-asignation.name-required") || "" },
                        ]}
                    >
                        <Input onChange={handleInputChange} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} style={{ textAlign: "right" }}>
                    <Button
                        loading={props.isLoading}
                        className="app-button"
                        disabled={props.isLoading || disableButton}
                        htmlType="submit"
                    >
                        {props.selectedConfiguration ? t("button.edit") : t("button.create")}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default AccountAsignationForm;
