import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import { getCountryCode } from '../../../utils/constants';
import './LanguageSelectorNews.scss';

interface LanguageSelectorProps {
    languages: string[];  // Lista de idiomas
    selectedLanguage: string;  // Idioma seleccionado
    onLanguageChange: (value: string) => void;  // Función para manejar el cambio de idioma
}


const LanguageSelectorNews = (props : LanguageSelectorProps): JSX.Element => {

    const { t } = useTranslation();

    return (
       <div className='language-selector-news'>
        <Select
            value={props.selectedLanguage} 
            onChange={props.onLanguageChange} 
            className="app-select language"
        >
            {props.languages.map((language) => (
                <Select.Option key={language} value={language}>
                    <span>
                    <ReactCountryFlag style={{marginRight:7}} countryCode={getCountryCode(language)} svg />
                    {t("languages." + language)}                        
                    </span>                   
                </Select.Option>
            ))}
        </Select>
        </div>
       
    );
};

export default LanguageSelectorNews;
