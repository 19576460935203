import './CommunicationsPage.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import FilterCommunicationsComponent from '../../../components/Communications/FilterCommunications/FilterCommunicationsComponent';
import { ChangeNewsStatus, deleteNews, fetchNews } from '../../../api/repositoryEP';
import { useEffect, useState } from 'react';
import { NewsData, NewsFilter } from '../../../models/CommunicationsData';
import ListCommunicationsComponent from '../../../components/Communications/ListCommunications/ListCommunicationsComponent';
import { CustomPagination } from '../../../components';
import { PageSize } from '../../../utils/constants';
import NoDataComponent from '../../../components/common/NoData/NoDataComponent';
import ModalGeneric from '../../../components/TabConfiguration/components/MappingConfig/TabMapping/Modals/ModalGeneric';
import { GenericModal } from '../../../components/common/Modal/GenericModal';

const CommunicationsPage = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [newsData, setNewsData] = useState<NewsData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [prefixImg, setPrefixImg] = useState<string>("");


    const [actualPage, setActualPage] = useState<number>(1);
    const [actualPageSize, setActualPageSize] = useState<number>(PageSize);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [actualFilter, setActualFilter] = useState<NewsFilter>({
        title: "",
        status: "",
        page: 0,
        pageSize: PageSize,
    });

    useEffect(() => {
        onSearch(actualFilter);
    }, [actualFilter]);



    const onSearch = async (data: NewsFilter) => {
        setIsLoading(true);
        fetchNews(data.title, data.status, data.page, data.pageSize).then((r) => {
            if (r?.type === 1 && r?.data) {
                setNewsData(r?.data.elements as NewsData[]);
                setTotalRecords(r?.data?.totalRecords ?? 0);
                console.log(r?.data?.prefix);
                setPrefixImg(r?.data?.prefix);
            } else {
                setNewsData([]);
                setTotalRecords(0);
            }
            setIsLoading(false);
        });
    }

    const onSearchWithoutLoading = async (data: NewsFilter) => {

        fetchNews(data.title, data.status, data.page, data.pageSize).then((r) => {
            if (r?.type === 1 && r?.data) {
                setNewsData(r?.data.elements as NewsData[]);
                setTotalRecords(r?.data?.totalRecords ?? 0);
            } else {
                setNewsData([]);
                setTotalRecords(0);
            }

        });
    }

    const createNew = () => {
        navigate(`${window.location.pathname}/new`);
    };

    const onChangePagination = (newPage: number, newPageSize?: number | undefined) => {
        setActualPage(newPage);
        setActualPageSize(newPageSize ?? PageSize);
        setActualFilter({
            ...actualFilter,
            page: newPage - 1,
            pageSize: newPageSize ?? PageSize
        });
    }

    const [idSelected, setIdSelected] = useState<string>("");
    const [visibleModalDeleteNews, setVisibleModalDeleteNews] = useState<boolean>(false);
    const [visibleModalUnpublishedNews, setVisibleModalUnpublishedNews] = useState<boolean>(false);

    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);

    const handleCancel = () => {
        setIdSelected(""); // Limpia el ID seleccionado
    };

    const DeleteNews = () => {
        setIsLoadingModal(true);
        deleteNews(idSelected).then((r) => {
            if (r?.type === 1) {
                onSearchWithoutLoading(actualFilter);
                setIdSelected("");
            }
            setIsLoadingModal(false);
        });


    };

    useEffect(() => {

        console.log("idSelected changed", idSelected)
    }, [idSelected]);

    useEffect(() => { console.log("está cargando el modal  :", isLoadingModal) }, [isLoadingModal]);


    const changeNewStatus = (id: string, isActive: boolean) => {
        console.log("llega ")
        setIsLoadingModal(true);
        ChangeNewsStatus(id, isActive).then((r) => {
            if (r?.type == 1) {
                handleCancel();
                onSearchWithoutLoading(actualFilter);
            }
            setIsLoadingModal(false);
        });

    };



    return (
        <div className="communications-page">
            {
                idSelected && <GenericModal
                    title={t("components.communications.delete-new")}
                    visible={visibleModalDeleteNews}
                    onCancel={handleCancel}
                    isLoading={isLoadingModal}
                    setModalVisible={setVisibleModalDeleteNews}
                    content={<div>{t("components.communications.delete-new-message")}</div>}
                    onOk={() => {
                        console.log("IDSelected último paso", idSelected)
                        DeleteNews();
                    }}
                />
            }


            {
                idSelected && <GenericModal
                    title={t("components.communications.unpublish-new")}
                    visible={visibleModalUnpublishedNews}
                    setModalVisible={setVisibleModalUnpublishedNews}
                    isLoading={isLoadingModal}
                    onCancel={handleCancel}
                    content={<div>{t("components.communications.unpublish-new-message")}</div>}
                    onOk={() => changeNewStatus(idSelected, false)}
                />}


            <Row gutter={[20, 20]} className="communications-row">
                <Col xs={24} md={24}>
                    <h2 className="title">
                        {t("components.communications.tittle")}
                    </h2>
                </Col>
                <Col xs={24} md={18} className="text">
                    {t("components.communications.description")}
                </Col>
                <Col xs={24} md={6} className="text">
                    <Button onClick={createNew} className='app-button'>{t("components.communications.create-new")}</Button>
                </Col>

            </Row>
            <Row gutter={[0, 0]} className='container'>
                <Col xs={24} md={24} >
                    <FilterCommunicationsComponent searching={isLoading} onSearch={onSearch} />
                </Col>

                {
                    newsData.length === 0 && !isLoading ? <NoDataComponent />
                        :
                        <>

                            <Col xs={24} md={24} >
                                <ListCommunicationsComponent
                                    changeNewStatus={async (id, isActive) => {
                                        await ChangeNewsStatus(id, isActive); // Función que hace el cambio de estado
                                        await onSearchWithoutLoading(actualFilter);
                                    }}
                                    prefixImg={prefixImg}
                                    list={newsData}
                                    loading={isLoading} // Indica si la lista está cargando
                                    setVisibleModalUnpublishedNews={setVisibleModalUnpublishedNews}
                                    setDeleteModalVisible={setVisibleModalDeleteNews}
                                    setIdSelected={setIdSelected}

                                />

                            </Col>
                            {
                                !isLoading &&
                                <div className='pagination-container'>
                                    <CustomPagination actualPage={actualPage} actualPageSize={actualPageSize} totalElements={totalRecords} onChange={onChangePagination} />
                                </div>
                            }
                        </>
                }
            </Row>

        </div>
    );
};



export default CommunicationsPage;
