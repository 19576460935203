

import { Card, Drawer, Switch, Tooltip } from 'antd';
import AccountData from '../../../models/AccountData';
import './AccounstListComponent.scss'
import { GetAccountsIcon, getAccountTypeIcon } from '../../../utils/icons';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SmallCompanyData } from '../../../models/SmallCompanyData';
import { SmallAgencyData } from '../../../models/SmallAgencyData';
import AccountUsersPage from '../../../pages/Private/AccountUsersPage/AccountUsersPage';
import { useNavigate } from 'react-router-dom';
import { GetFormattedUrl } from '../../../utils/urls';
import { changeAccountStatus } from '../../../api/repositoryEP';
import { LoadingData } from '../../common';
import { AreaChartOutlined, DotChartOutlined, MailOutlined, SignatureOutlined } from '@ant-design/icons';
import HTMLReactParser from 'html-react-parser';

interface AccountsListComponentProps {
    list: AccountData[];
    loading: boolean;
    companies: SmallCompanyData[];
    agencies: SmallAgencyData[];
}

const AccountsListComponent = (props: AccountsListComponentProps): JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();
    const { list, loading, companies, agencies } = props;
    const navigate = useNavigate();
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<AccountData>();


    const goBookings = (account: AccountData) => {
        navigate(GetFormattedUrl(`private/bookings-admin?type=${account.type}&account=${account.id}`))
    }

    const showEditDrawer = (item: AccountData) => {
        setSelected(item);
        setDrawerVisible(true);
    }

    useEffect(() => {
        console.log("Updated", companies, agencies)
    }, [agencies, props.agencies, companies, props.companies])


    const changeStatus = (id: string, isActive: boolean, type: string) => {
        changeAccountStatus(id, isActive, type).then((r) => { });
    }

    return (
        <div className="accounts-list">
            <Drawer open={drawerVisible} title={<span>{selected?.commercialName}<small style={{ display: "block" }}>{selected?.address}</small></span>} onClose={() => { setDrawerVisible(false) }} width={"50%"} closable>
                <AccountUsersPage accountData={selected} />
            </Drawer>
            {
                loading && <LoadingData />
            }

            {
                !loading && list.map((item: AccountData, index: number) => {
                    var cardHeader: JSX.Element = <div className="header-container" key={index}>
                        <div className="account-type">
                            <span>
                                <Tooltip title={t(`components.accounts.list.account-type-${item.type}`)}>
                                    {getAccountTypeIcon(item.type)}
                                </Tooltip>
                            </span>
                            <span>
                                {item.commercialName}
                                {item.creationDate && (
                                    <small>{t('components.accounts.list.creation-date')}: {moment(item.creationDate).format(appConfig.config.dateFormat)}</small>
                                )}

                            </span>

                        </div>
                        <div className="actions-container">
                            <Switch
                                className="app-switch"
                                defaultChecked={item.status === 'active'}
                                onChange={(checked) => changeStatus(item.id, checked, item.type)} // Capturamos el valor 'checked' del switch
                            />
                        </div>

                    </div>

                    return (
                        <Card className="app-card" title={cardHeader} bordered={false}>
                            <div className="body-container">
                                {GetAccountsIcon('app-icon width-14px')}
                                <div className="additional-info">
                                    <div className="names">
                                        {item.manager && <span>{item.manager}</span>}
                                        {item.address && <span>{item.address}</span>}
                                    </div>
                                    <AreaChartOutlined className='app-icon width-14px'/>
                                    <div className="statistics">
                                        {HTMLReactParser(t('components.accounts.list.statistics.title'))}:
                                        <ul className="statistics-list">
                                            <li><strong>{t('components.accounts.list.statistics.requests')}</strong>: {item.totalAccountRequests ?? 0} </li>
                                            <li><strong>{t('components.accounts.list.statistics.active-bookings')}</strong>: {item.activeBookingCounter ?? 0} </li>
                                            <li><strong>L2B</strong>: {item.conversionRatio ?? 0}%</li>
                                        </ul>
                                    </div>
                                    <SignatureOutlined className='app-icon width-14px'/>
                                    <div className="contract">
                                        {HTMLReactParser(t('components.accounts.list.contract.title'))}:
                                        <ul className="statistics-list">
                                            <li>{item.contractName} </li>
                                        </ul>
                                    </div>
                                    <MailOutlined className="app-icon width-14px"/>
                                    <div className="contact">
                                        {item.contact?.email && (
                                            <span>
                                                <strong>{t('components.accounts.list.email')}</strong>: <a href={`mailto:${item.contact.email}`}>{item.contact.email}</a>
                                            </span>
                                        )}
                                        {item.contact?.phone && (
                                            <span>
                                                <strong>{t('components.accounts.list.phone')}</strong>: {item.contact.phone}
                                            </span>
                                        )}
                                    </div>
                                    <div className="resume">
                                        {item.resume?.usersNumber !== undefined && (
                                            <span
                                                onClick={item.resume.usersNumber > 0 ? () => showEditDrawer(item) : undefined} // Solo se ejecuta si usersNumber es mayor que 0
                                                style={{ cursor: item.resume.usersNumber > 0 ? "pointer" : "default" }} // Cambia el cursor dependiendo del valor
                                            >
                                                <strong>{t('components.accounts.list.number-of-users')}  </strong>:
                                                <span
                                                    className="app-link"
                                                    style={{
                                                        textDecoration: item.resume.usersNumber > 0 ? "underline" : "none", // Solo subraya si el valor es mayor que 0
                                                        color: item.resume.usersNumber > 0 ? "blue" : "black" // Cambia el color para indicar si es clicable
                                                    }}
                                                >
                                                    {item.resume.usersNumber}
                                                </span>
                                            </span>
                                        )}

                                        {item.resume?.bookingNumber !== undefined && (
                                            <span
                                                onClick={item.resume.bookingNumber > 0 ? () => goBookings(item) : undefined} // Solo se ejecuta si bookingNumber es mayor que 0
                                                style={{ cursor: item.resume.bookingNumber > 0 ? "pointer" : "default" }} // Cambia el cursor dependiendo del valor
                                            >
                                                <strong>{t('components.accounts.list.number-of-bookings')}  </strong>:
                                                <span
                                                    className="app-link"
                                                    style={{
                                                        textDecoration: item.resume.bookingNumber > 0 ? "underline" : "none", // Solo subraya si el valor es mayor que 0
                                                        color: item.resume.bookingNumber > 0 ? "blue" : "black" // Cambia el color para indicar si es clicable
                                                    }}
                                                >
                                                    {item.resume.bookingNumber}
                                                </span>
                                            </span>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </Card>

                    )
                })
            }
        </div>
    )
}

export default AccountsListComponent;