import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// Private Pages
import DashboardPage from './pages/Private/DashboardPage/DashboardPage';

// Public Pages
import LoginPage from './pages/Public/LoginPage/LoginPage';

// Common pages
import Page404 from './pages/Commons/404/404';
import Page500 from './pages/Commons/500/500';
import Page403 from './pages/Commons/403/403';
import TermnsAndConditionsPage from './pages/Commons/LegalTexts/TermsAndConditions/TermnsAndConditionsPage';
import PrivacityPage from './pages/Commons/LegalTexts/Privacity/PrivacityPage';
import LegalWarningPage from './pages/Commons/LegalTexts/LegalWarning/LegalWarningPage';
import CookiesPage from './pages/Commons/LegalTexts/Cookies/CookiesPage';


import { useEffect, useState } from 'react';
import { DEFAULT_LANGUAGE } from './i18n/i18n';
import { IsLaguagePresentInUrl } from './utils/urls';
import { useSessionData } from './Contexts/SessionDataContext';
import PrivatePageWrapper from './pages/Private/PrivatePageWrapper';
import PublicPageWrapper from './pages/Public/PublicPageWrapper';
import LoadingPage from './pages/Commons/LoadingPage/LoadingPage';
import AccountsPage from './pages/Private/AccountsPage/AccountsPage';
import BookingsPage from './pages/Private/BookingsPage/BookingsPage';
import PaymentsConfigPage from './pages/Private/PaymentsConfigPage/PaymentsConfigPage';
import UsersPage from './pages/Private/UsersPage/UsersPage';
import ProfilePage from './pages/Private/ProfilePage/ProfilePage';
import ConfigurationPage from './pages/Private/ConfigurationPage/ConfigurationPage';
import ProConfiguration from './components/TabConfiguration/components/ProConfiguration/ProConfiguration';
import { fetchAvailableClientLanguages } from './api/repositoryEP';
import ContractsPage from './pages/Private/ContractsPage/ContractsPage';
import LandingPage from './components/TabConfiguration/components/LandingPage/LandingPage';
import RegisterForm from './components/TabConfiguration/components/RegisterForm/RegisterForm';
import GroupForm from './components/TabConfiguration/components/GroupForm/GroupForm';
import MappingConfig from './components/TabConfiguration/components/MappingConfig/MappingConfig';
import TabConfiguration from './components/TabConfiguration/TabConfiguration';
import CommunicationsPage from './pages/Private/CommunicationsPage/CommunicationsPage';
import NewsDetailPage from './pages/Private/NewsDetailPage/NewsDetailPage';
import NewsPage from './pages/Private/NewsPage/NewsPage';
import AccountAsignationPage from './pages/Private/AccountAsignation/AccountAsignationPage';

let useLocationStr = '';

export const GetAppLanguage = (): string => useLocationStr.length > 0 ? useLocationStr : DEFAULT_LANGUAGE;

export const ChangeAppLanguage = (params: any) => {
  const location = useLocation();
  let iso18Info: any = null;

  useEffect(() => {
    useLocationStr = '';
    if (IsLaguagePresentInUrl()) {
      useLocationStr = location.pathname.split('/')[1];
    }

    params.i18n.changeLanguage(useLocationStr);

    iso18Info = params.i18n;

  }, [params.i18n])
  return <></>;
};

export const UpdateAppLanguage = (shortCode: string): void => {
  if (IsLaguagePresentInUrl()) {
    var path = window.location.pathname.split("/")
    path[1] = shortCode;
    window.location.href = path.join("/");
  } else {
    const pathName = window.location.pathname && window.location.pathname.length > 0 ? `${window.location.pathname}` : '';
    const finalUrl = `/${shortCode}${pathName}`;

    window.location.href = finalUrl;
  }
}


interface RoutesAppParams {
  i18n: any
}
const RoutesApp = (params: RoutesAppParams): JSX.Element => {
  const sessionData = useSessionData();

  const isLogged = sessionData.isLogged;
  const [language, setLanguage] = useState<string>('');


  useEffect(() => {
    setLanguage(useLocationStr);
  }, [useLocationStr])

  return (
    <Router>
      <ChangeAppLanguage i18n={params.i18n} />
      {
        isLogged ? <PrivateRoutes language={language} /> : <PublicRoutes language={language} />
      }
    </Router>
  );
}


interface RouteParams {
  language: string
}
const PrivateRoutes = (params: RouteParams): JSX.Element => {

  const [language, setLanguage] = useState<string>(params.language);
  const [clientLanguages, setClientLanguages] = useState<string[]>([]);

  useEffect(() => {
    fetchAvailableClientLanguages().then((response) => {
      if (response?.code === 200) {
        setClientLanguages(response.data);
      }
    });
  });

  useEffect(() => {
    setLanguage(params.language);
  }, [params.language])

  return (
    <Routes>
      <Route path={`/:language/private/dashboard`} element={<PrivatePageWrapper children={<DashboardPage />} />} />
      <Route path={`/:language/private/accounts-admin`} element={<PrivatePageWrapper children={<AccountsPage />} />} />
      <Route path={`/:language/private/bookings-admin`} element={<PrivatePageWrapper children={<BookingsPage />} />} />
      <Route path={`/:language/private/payments-config`} element={<PrivatePageWrapper children={<PaymentsConfigPage />} />} />
      <Route path={`/:language/private/users`} element={<PrivatePageWrapper children={<UsersPage />} />} />
      <Route path={`/:language/private/forbiden/*`} element={<PrivatePageWrapper children={<Page403 />} />} />
      <Route path={`/:language/private/error/*`} element={<PrivatePageWrapper children={<Page500 />} />} />
      <Route path={`/:language/private/*`} element={<PrivatePageWrapper children={<Page404 />} />} />
      <Route path={`/:language/private/403`} element={<PrivatePageWrapper children={<Page403 />} />} />
      <Route path={`/:language/private/404`} element={<PrivatePageWrapper children={<Page404 />} />} />
      <Route path={`/:language/private/500`} element={<PrivatePageWrapper children={<Page500 />} />} />
      <Route path={`/:language/private/loading-page`} element={<PrivatePageWrapper children={<LoadingPage />} />} />
      <Route path={`/:language/private/profile`} element={<PrivatePageWrapper children={<ProfilePage />} />} />
      <Route path={`/:language/private/config/*`}element={<PrivatePageWrapper children={<ConfigurationPage />} />} />
      <Route path={`/:language/private/contracts`} element={<PrivatePageWrapper children={<ContractsPage />} />}/>
      <Route path={`/:language/private/commissions`} element={<PrivatePageWrapper children={<AccountAsignationPage />} />}/>
      <Route path={`/:language/private/communications`}element={<PrivatePageWrapper children={<CommunicationsPage />} />} />
      <Route path={`/:language/private/communications/new-details/:id`} element={<PrivatePageWrapper children={<NewsDetailPage />} />} />
      <Route path={`/:language/private/communications/new/:id`} element={<PrivatePageWrapper children={<NewsPage />} />} />
      <Route path={`/:language/private/communications/new`} element={<PrivatePageWrapper children={<NewsPage />} />} />
    </Routes>
  );
}

const PublicRoutes = (params: RouteParams): JSX.Element => {
  const [language, setLanguage] = useState<string>(params.language);

  useEffect(() => {
    setLanguage(params.language);
    if (window.location.pathname.indexOf("private") === -1) {
      sessionStorage.removeItem("user-data");
    }
  }, [params.language])


  return (
    <Routes>
      <Route path="/:language/" element={<PublicPageWrapper showMenu widthPage='no-margin' children={<LoginPage />} />} />
      <Route path="/:language/login" element={<PublicPageWrapper showMenu widthPage='no-margin' children={<LoginPage />} />} />
      <Route path="/:language/termns-and-conditions" element={<PublicPageWrapper showMenu widthPage='width-100' children={<TermnsAndConditionsPage />} />} />
      <Route path="/:language/privacity" element={<PublicPageWrapper showMenu widthPage='width-100' children={<PrivacityPage />} />} />
      <Route path="/:language/legal-warning" element={<PublicPageWrapper showMenu widthPage='width-100' children={<LegalWarningPage />} />} />
      <Route path="/:language/cookies" element={<PublicPageWrapper showMenu widthPage='width-100' children={<CookiesPage />} />} />

      <Route path="/:language/error/*" element={<PublicPageWrapper showMenu widthPage='width-100' children={<Page500 />} />} />
      <Route path="/:language/forbiden/*" element={<PublicPageWrapper showMenu widthPage='width-100' children={<Page403 />} />} />
      <Route path="/:language/*" element={<PublicPageWrapper showMenu widthPage='width-100' children={<Page404 />} />} />

      <Route path={`/:language/forbiden/*`} element={<Page403 />} />
      <Route path={`/:language/*`} element={<Page404 />} />

      <Route path={`/:language/private/*`} element={<PublicPageWrapper onlyContent widthPage='width-100' children={<LoadingPage />} />} />
    </Routes>
  );
}

export default RoutesApp;
