import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import { Col, Row, Select } from "antd";
import { KeyValue } from "../../../models/CommunicationsData";
import { PaymentConfigurationsFilter } from "../../../models/PaymentMethodData";
import { useState, useMemo } from "react";

interface PaymentsMethodFilterProps {
    setActualFilter: (filter: PaymentConfigurationsFilter) => void;
    actualFilter: PaymentConfigurationsFilter;
    paymentsMethod: KeyValue[];
}

const PaymentsMethodFilter = (props: PaymentsMethodFilterProps): JSX.Element => {
    const { t } = useTranslation();
    const [paymentMethodSelected, setPaymentMethodSelected] = useState<string>("");

    // Memorizar las opciones del selector
    const paymentOptions = useMemo(() => [
        { value: "", label: t("pages.payments-configuration.any-payment-method") || "" },
        ...props.paymentsMethod.map((method) => ({
            value: method.key,
            label: method.value,
        })),
    ], [props.paymentsMethod, t]);

    // Manejar cambios en el buscador
    const handleSearch = (value: string) => {
        props.setActualFilter({
            ...props.actualFilter,
            name: value,
        });
    };

    // Manejar cambios en el selector
    const handleSelectChange = (value: string) => {
        setPaymentMethodSelected(value);
        props.setActualFilter({
            ...props.actualFilter,
            paymentMethodId: value,
        });
    };

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Search
                    className="app-search"
                    style = {{ width: "100%", fontFamily: "Montserrat" }}
                        allowClear
                        placeholder={t("button.search") || ""}
                        onSearch={handleSearch}
                    />
                </Col>



                <Col xs={24} md={12}>
                    {/* Select con métodos de pago */}
                    <Select
                        options={paymentOptions}
                        value={paymentMethodSelected}
                        style={{ width: "100%", fontFamily: "Montserrat" }}
                        onChange={handleSelectChange}
                    />
                </Col>
            </Row>


        </div>
    );
};

export default PaymentsMethodFilter;
