

import { useTranslation } from 'react-i18next';
import { Button, Col, Drawer, Row } from 'antd';
import { useEffect, useState } from 'react';
import { ConfigurationsFilter, KeyValue } from '../../../models/AccountAsignationData';
import { AccountAsignationFilter, AccountAsignationList, CustomPagination } from '../../../components';
import { CreatePaymentMethodConfiguration, DeletePaymentsConfiguration, EditPaymentMethodConfiguration, GetCountries, GetPaymentsMethodConfiguration, GetPaymentsMethods } from '../../../api/repositoryEP';
import { useSessionData } from '../../../Contexts/SessionDataContext';
import { PageSize } from '../../../utils/constants';
import { EditPaymentConfigurationData, PaymentConfigurationData, PaymentConfigurationsFilter } from '../../../models/PaymentMethodData';
import PaymentsMethodList from '../../../components/payments/list/PaymentsMethodList';
import PaymentsMethodForm from '../../../components/payments/form/PaymentsMethodForm';
import './PaymentsConfigPage.scss';
import PaymentsMethodFilter from '../../../components/payments/filter/PaymentsMethodFilter';
import { NoData } from '../../../components/common';


const PaymentsConfigPage = (): JSX.Element => {
    const [paymentsConfigList, setPaymentsConfigList] = useState<PaymentConfigurationData[]>([]);
    const [selectedConfiguration, setSelectedConfiguration] = useState<PaymentConfigurationData | null>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [executing, setIsExecuting] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const { t } = useTranslation();
    const sessionData = useSessionData();


    const [actualFilter, setActualFilter] = useState<PaymentConfigurationsFilter>({ paymentMethodId: "", name: "", page: 0, pageSize: PageSize });
    const [totalRecords, setTotalRecords] = useState<number>(0)


    const [markets, setMarkets] = useState<KeyValue[]>([]); // id,isoCountryCode
    const [paymentsMethods, setPaymentsMethods] = useState<KeyValue[]>([]); // id,name



    const getPaymentsMethods = async () => {
        const r = await GetPaymentsMethods();
        if (r?.type === 1) {
            setPaymentsMethods(r.data as KeyValue[]);
        }
    };

    const getMarkets = async () => {
        const r = await GetCountries();
        if (r?.type === 1) {
            setMarkets(r.data as KeyValue[]);
        }
    };

    const getPaymentsConfiguration = async () => {
        const r = await GetPaymentsMethodConfiguration(actualFilter);
        if (r?.type === 1) {
            setPaymentsConfigList(r.data.configurations as PaymentConfigurationData[]);
            console.log(r.data.configurations as PaymentConfigurationData[]);
            setTotalRecords(r.data.totalRecords);
        } else {
            setPaymentsConfigList([]);
            setTotalRecords(0);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setIsLoadingData(true);
            try {
                await Promise.all([getPaymentsMethods(), getMarkets(), getPaymentsConfiguration()]);
            } finally {
                setIsLoadingData(false);
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        const fetchPaymentsConfiguration = async () => {
            setIsLoadingData(true);
            await getPaymentsConfiguration();
            setIsLoadingData(false);
        };

        fetchPaymentsConfiguration();
    }, [actualFilter]);




    const onSave = async (data: EditPaymentConfigurationData): Promise<void> => {
        setIsExecuting(true);
        try {
            const response = await EditPaymentMethodConfiguration(data); // Esperar la operación
            if (response?.type === 1) {
                setPaymentsConfigList((prevList) =>
                    prevList.map((item) =>
                        item.id === data.id
                            ? {
                                ...item,
                                ...Object.fromEntries(
                                    Object.entries(data).filter(([_, value]) => value !== null)
                                ),
                            }
                            : item
                    )
                );
            }
        } finally {
            setIsExecuting(false);
        }
    };


    const onEdit = async (data: EditPaymentConfigurationData): Promise<void> => {
        setIsExecuting(true);
        return EditPaymentMethodConfiguration(data)
            .then((r: any) => {
                if (r?.type === 1) {
                }
            })
            .finally(() => {
                setIsExecuting(false);
            });
    };


    const onEditName = (id: string, name: string): Promise<void> => {
        setIsExecuting(true);

        const c: EditPaymentConfigurationData = {
            id: id,
            name: name,
            isActive: null,
            paymentsMethodSelected: null,
            marketsSelected: null,
            accountsSelected: null,
        };

        return EditPaymentMethodConfiguration(c)
            .then((r) => {
                if (r?.type === 1) {
                    setPaymentsConfigList(
                        paymentsConfigList.map((config) =>
                            config.id === id ? { ...config, name: name } : config
                        )
                    );
                }
            })
            .finally(() => {
                setIsExecuting(false);
                setSelectedConfiguration(null);
                setDrawerVisible(false);
            });
    };






    //AQUI ME PUEDE FALTAR COSITAS, EL BOTON VAYA
    const onDelete = async (id: string): Promise<void> => {
        return DeletePaymentsConfiguration(id).then((r) => {
            if (r?.type === 1 && paymentsConfigList) {
                setPaymentsConfigList(paymentsConfigList.filter((c) => c.id !== id));
                setTotalRecords(totalRecords - 1);
            }
        });
    };


    const onCreate = async (name: string): Promise<void> => {
        setIsExecuting(true);

        CreatePaymentMethodConfiguration(sessionData.userData.id, name)
            .then((r) => {
                if (r?.type === 1) {
                    setActualFilter({
                        ...actualFilter, // Mantener el resto de las propiedades del filtro
                        page: actualFilter.page,
                        pageSize: actualFilter.pageSize,
                        name: ""
                    });
                }
            })
            .finally(() => {
                setIsExecuting(false);
                setSelectedConfiguration(null);
                setDrawerVisible(false);
            });
    };



    const [actualPage, setActualPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(PageSize);

    const onChangePagination = (newPage: number, newPageSize?: number | undefined) => {
        setActualPage(newPage);
        setPageSize(newPageSize ?? pageSize);
        setActualFilter({
            ...actualFilter,
            page: newPage - 1,
            pageSize: newPageSize ?? pageSize
        });
    }




    return (
        <Row className="payments-configuration-page" gutter={[20, 20]}>
            <Drawer open={drawerVisible}
                title={t("pages.payments-configuration.title-create-configuration")}
                onClose={() => { setSelectedConfiguration(null); setDrawerVisible(false) }}
                width={"50%"}
                closable>
                <PaymentsMethodForm
                    isLoading={executing}
                    create={onCreate}
                    editName={onEditName}
                    selectedConfiguration={selectedConfiguration}
                    setSelectedConfiguration={setSelectedConfiguration}
                />
            </Drawer>
            <Col xs={24} md={24}>
                <h2 className="title">
                    {t('pages.payments-configuration.title')}
                </h2>
            </Col>
            <Col xs={24} md={24}>
                <p>
                    {t('pages.payments-configuration.welcome-message')}
                </p>
            </Col>
            <Col xs={24} sm={24} md={18}>
                <PaymentsMethodFilter
                    paymentsMethod={paymentsMethods}
                    actualFilter={actualFilter}
                    setActualFilter={setActualFilter}
                />
            </Col>
            <Col xs={24} sm={24} md={6} className='button-container'>
                <Button
                    className="app-button"
                    disabled={isLoadingData}
                    onClick={() => {
                        setSelectedConfiguration(null);
                        setDrawerVisible(true);
                    }}
                >
                    + {t('pages.payments-configuration.create-configuration')}
                </Button>
            </Col>
            <Col xs={24} md={24}>
                {
                    paymentsConfigList?.length > 0 || isLoadingData
                        ?
                        <PaymentsMethodList
                            configurations={paymentsConfigList}
                            edit={onEdit}
                            save={onSave}
                            delete={onDelete}
                            setSelectedConfiguration={setSelectedConfiguration}
                            isLoading={isLoadingData}
                            setEditDrawerVisible={setDrawerVisible}
                            markets={markets}
                            paymentsMethods={paymentsMethods}
                        />
                        :
                        <><NoData /></>

                }

            </Col>
            <div className="footer">
                {
                 paymentsConfigList?.length > 0   && !isLoadingData && <div className="pagination-container">
                        <CustomPagination actualPage={actualPage} actualPageSize={pageSize} totalElements={totalRecords} onChange={onChangePagination} />
                    </div>
                }
            </div>
        </Row>
    )
}

export default PaymentsConfigPage;