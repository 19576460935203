
import { useEffect, useState } from 'react';
import Contract from '../../../models/Contract';

import './ContractsListComponent.scss';
import { ContractElement } from '../..';

interface ContractListComponentProps {
    onChangeContractStatus: (isEnabled: boolean, contractId: string) => void;
    onRemoveContract: (contractId: string) => void;
    onEditContract: (contractId: string) => void;
    onGetAssignedAccountsContract: (contractId: string) => void;
    contracts: Contract[];
}

const ContractsListComponent = (props: ContractListComponentProps) : JSX.Element => {


    const [contract, setContracts] = useState<Contract[]>(props.contracts);

    const onEditContract = (contractId: string) => {
        props.onEditContract(contractId);
    };

    const onRemoveContract = (contractId: string) => {
        props.onRemoveContract(contractId);
    };

    const onGetAssignedAccountsContract = (contractId: string) => {
        props.onGetAssignedAccountsContract(contractId);
    };

    const onChangeContractStatus = (isEnabled: boolean, contractId: string) => {
        props.onChangeContractStatus(isEnabled, contractId);
    };

    useEffect(()=>{
        setContracts(props.contracts ?? []);
    }, [props.contracts]);

    return(
        <div className="contract-list">
            {
                contract.map((contract: Contract, index: number) => {
                    return <ContractElement 
                                key={index} 
                                showActions
                                contract={contract} 
                                onChangeContractStatus={onChangeContractStatus}
                                onRemoveContract={onRemoveContract}
                                onEditContract={onEditContract}
                                onGetAssignedAccountsContract={onGetAssignedAccountsContract}                                
                                />
                })
            }
        </div>
    )
}





export default ContractsListComponent;