import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Button, Input } from "antd";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface GenericModalProps {
  title: string;
  closable?: boolean;
  content: JSX.Element;
  okDanger?: boolean;
  onCancel: () => void;
  requiredTextConfirmation?: string
  onOk?: () => void;
  visible: boolean;
  isLoading: boolean;
  setModalVisible: (visible: boolean) => void;
}

export const GenericModal = (param: GenericModalProps) => {
  const { t } = useTranslation();

  const handleOk = () => {
    if (param.onOk) {
      param.onOk();
    }
  };

  const handleCancel = () => {
    param.setModalVisible(false);
    param.onCancel();
  };

  useEffect(()=>{
    getButtons();
    
  }, [param.isLoading]);

  const [acceptButtonIsEnabled, setAcceptButtonIsEnabled] = useState<boolean>(param.requiredTextConfirmation ? false : true);
  const [buttons, setButtons] = useState<JSX.Element[]>([]);
  const getButtons = () => {
    var buttons = [];

    buttons.push(
      <Button
        key="cancel"
        className="app-button "
        onClick={handleCancel}>
        {t("button.cancel")}
      </Button>
    );


    if (param.onOk) {
      buttons.push(
        <Button key="ok"
          type="primary"
          loading={param.isLoading}
          disabled={param.isLoading || !acceptButtonIsEnabled}
          className={`app-button ${param.okDanger ? "danger" : ""}`}
          onClick={handleOk}>
          {t("button.accept")}
        </Button>
      );
    }

    setButtons(buttons);
  }

  const checkTextConfirmation = (event: any) => {
    console.log("event.target.value === param.requiredTextConfirmation", event.target.value === param.requiredTextConfirmation);
    setAcceptButtonIsEnabled(event.target.value === param.requiredTextConfirmation);
  }

  useEffect(() => {
    getButtons();
  }, [acceptButtonIsEnabled])

  useEffect(()=>{
    getButtons();
  },[])

  return (

    <Modal
      title={param.title}
      className="app-modal"
      open={param.visible}
      closable={param.closable}
      //loading={isLoading}
      footer={buttons}
      onOk={param.onOk}
      onCancel={handleCancel}
    >
      {param.content}

      {
        param.requiredTextConfirmation && param.requiredTextConfirmation.length > 0 &&
        <div className="required-text">
          {HTMLReactParser(t('actions.required-text-complete-confirmation', [param.requiredTextConfirmation]))}
          <Input placeholder={param.requiredTextConfirmation} onChange={checkTextConfirmation} type="text" className="app-input required-text-confirmation" required/>
        </div>
      }
      
    </Modal>

  );
};
