import { Button, Col, Row, Select, Form, DatePicker, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { AccountAsignationData, EditAccountAsignationData, KeyValue } from "../../../models/AccountAsignationData";
import { EditOutlined } from "@ant-design/icons";
import './AccountAsignationListComponent.scss';
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { GenericModal } from "../../common/Modal/GenericModal";
import { CalculateAffectedAccounts, fetchAccounts, GetConfigurationAccounts } from "../../../api/repositoryEP";
import debounce from "lodash/debounce";
import { GenericKeyValuePair } from "../../../models/CustomField";


interface AccountAsignationListComponent2Props {
    configuration: AccountAsignationData;

    setEditDrawerVisible: (isVisible: boolean) => void;
    setSelectedConfiguration: (aad: AccountAsignationData | null) => void; // Nueva prop


    contracts: KeyValue[],
    markets: KeyValue[],


    save: (data: EditAccountAsignationData) => void;
    edit: (data: EditAccountAsignationData) => void;
    delete: (id: string) => void;


}

const AccountAsignationElement = (props: AccountAsignationListComponent2Props): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [deleting, setIsDeleting] = useState<boolean>(false);
    const [saving, setIsSaving] = useState<boolean>(false);
    const [affectedAccounts, setAffectedAccounts] = useState<number>(props.configuration.affectedAccounts);
    const [isChangingStatus, setIsChangingStatus] = useState<boolean>(false);





    const onDeleteConfiguration = async (id: string) => {
        setIsDeleting(true);
        try {
            await props.delete(id); // Llama a la función de eliminación

        } finally {
            setIsDeleting(false);
        }
    };

    const handleSave = async (accountAsignation: AccountAsignationData, values: any) => {
        setIsSaving(true)


        var accountsData = values.accounts.map((accountId: string) => {
            var accountType = selectedAccountValues.find((accountValue) => accountValue.id === accountId)?.accountType;
            var data: GenericKeyValuePair = {
                key: accountId,
                value: accountType
            }

            return data;
        })

        const updatedAccount: EditAccountAsignationData = {
            id: accountAsignation.id,
            name: null,
            isActive: null,
            applyFrom: values.from, // Manejar formato de fecha según necesidad
            applyTo: values.to,
            marketsSelected: values.markets, // Ya contiene los keys seleccionados
            accountsSelected: accountsData, // Ya contiene los keys seleccionados
            contractSelected: values.contract, // Directamente el key seleccionado
        };



        try {
            await props.edit(updatedAccount);
        } finally {
            // Desactiva el estado de carga para este ID
            setIsSaving(false)
        }
    };


    const [modalVisible, setModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const pendingSwitchRef = useRef<{
        id: string;
        isActive: boolean;
    } | null>(null);

    const handleSwitchClick = (currentChecked: boolean, accountAsignation: AccountAsignationData) => {
        const pendingState = { id: accountAsignation.id, isActive: !currentChecked };
        pendingSwitchRef.current = pendingState;
        if (currentChecked) {
            setModalVisible(true);
        } else {
            confirmSwitchChange();
        }

    };


    const confirmSwitchChange = async () => {

        if (pendingSwitchRef.current) {
            const { id, isActive } = pendingSwitchRef.current;

            // Actualiza el estado en el servidor o realiza cualquier acción
            const updatedAccount: EditAccountAsignationData = {
                id,
                name: null,
                isActive,
                applyFrom: null,
                applyTo: null,
                marketsSelected: null,
                accountsSelected: null,

                contractSelected: null,
            };
            setIsLoadingModal(true);
            try {
                setIsChangingStatus(true)
                await props.save(updatedAccount); // Esperar la operación
            } finally {
                setIsChangingStatus(false)
                setIsLoadingModal(false);
                pendingSwitchRef.current = null;
                setModalVisible(false);
            }
        }
    };


    const cancelSwitchChange = () => {
        pendingSwitchRef.current = null; // Limpiar referencia
        setModalVisible(false);
    };




    const [loading, setLoading] = useState<boolean>(false);
    const [totalMatches, setTotalMatches] = useState<number>(0); // Total de coincidencias en la búsequeda
    const [options, setOptions] = useState(
        props.configuration.accountsSelected.map((account) => ({
            label: account.name, // Mostrar el 'value' como etiqueta
            value: account.id,  // Usar el 'key' como valor interno
            country: account.country, // O la propiedad específica que tengas para el país
            accountType: account.accountType,
        }))
    ); // Convierte KeyValue a un formato compatible
    const [selectedAccountValues, setSelectedAccountValues] = useState(
        props.configuration.accountsSelected.map((account) => ({
            id: account.id,
            name: account.name,
            accountType: account.accountType,
            country: account.country, // O la propiedad específica que tengas para el país
        }))
    );


    const [selectedCountries, setSelectedCountries] = useState<string[]>(props.configuration.marketsSelected); // Cantidad de opciones seleccionadas

    const handleSearch = useCallback(
        debounce(async (name) => {

            if (name) {
                setLoading(true);
                try {
                    const response = await GetConfigurationAccounts(selectedCountries, name);
                    if (response?.type === 1) {
                        // Formatear las opciones y excluir seleccionados
                        const formattedOptions = response.data.accounts
                            .map((account: any) => ({
                                label: <div className="account-info"><label>{account.name} </label><small>{account.actualContractName}</small><small>{account.address} ({account.country})</small></div>, // Nombre del account
                                value: account.id,
                                address: account.address,
                                accountType: account.accountType,
                                actualContractName: account.actualContractName,
                                country: account.country
                            }))
                            .filter((option: any) => !selectedAccountValues.includes(option.value)); // Excluir seleccionados

                        // Actualizar las opciones sin acumular duplicados
                        setOptions(formattedOptions);
                        setTotalMatches(formattedOptions.length);
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                setOptions([]); // Limpia si no hay búsqueda
                setTotalMatches(0);
            }
        }, 300),
        [selectedAccountValues, selectedCountries]
    );




    const handleChangeSelect = (values: string[]) => {
        console.log("options: ", options);
        console.log("values: ", values);

        // Obtener las opciones seleccionadas basadas en los nuevos valores
        const updatedSelected = options.filter(opt => values.includes(opt.value));

        // Combinar las cuentas previamente seleccionadas con las nuevas, sin duplicados
        const combinedSelected = [
            ...selectedAccountValues,
            ...updatedSelected.map(opt => ({
                id: opt.value,
                name: opt.label,
                country: opt.country,
                accountType: opt.accountType
            })),
        ].filter((value, index, self) =>
            index === self.findIndex(v => v.id === value.id) // Eliminar duplicados por ID
        );

        // Actualizar el estado local de selectedAccountValues
        setSelectedAccountValues(combinedSelected);

        // Actualizar el campo del formulario con los IDs seleccionados
        form.setFieldValue(
            'accounts',
            combinedSelected.map(account => account.id)
        );
    };

    const handleChangeDeselect = (value: string) => {
        console.log("Deseleccionado:", value);

        // Filtrar la cuenta eliminada del estado
        const updatedSelected = selectedAccountValues.filter(account => account.id !== value);
        setSelectedAccountValues(updatedSelected);

        // Actualizar el formulario con los IDs restantes
        form.setFieldValue(
            'accounts',
            updatedSelected.map(account => account.id)
        );

        setOptions(options.filter(option => option.value !== value));
    };

    useEffect(() => {
        // Sincronizar el campo del formulario con los IDs de las cuentas seleccionadas
        form.setFieldValue(
            'accounts',
            selectedAccountValues.map(account => account.id)
        );
    }, [selectedAccountValues, form]);



    useEffect(() => {
        return () => handleSearch.cancel();
    }, [handleSearch]);


    const handleSelectCountry = (value: string) => {
        // Agregar el país a la lista de países seleccionados
        setSelectedCountries((prevSelected) => [...prevSelected, value]);

        // Filtrar las cuentas seleccionadas que correspondan al país actual o a cualquier país en selectedCountries
        setSelectedAccountValues((prevSelected) =>
            prevSelected.filter(
                (account) =>
                    account.country === value || selectedCountries.includes(account.country)
            )
        );
    };

    const handleDeselectCountry = (value: string) => {
        // 1. Eliminar el país de la lista de países seleccionados
        setSelectedCountries((prevSelected) =>
            prevSelected.filter((country) => country !== value)
        );

        // 2. Actualizar las cuentas seleccionadas para eliminar las asociadas al país deseleccionado
        setSelectedAccountValues((prevSelected) =>
            prevSelected.filter((account) =>
                selectedCountries.filter((country) => country !== value).includes(account.country)
            )
        );

    };



    useEffect(() => {
        // Si hay países seleccionados y no cuentas seleccionadas
        if (selectedCountries.length > 0 && selectedAccountValues.length === 0) {
            // Llamar a la API para obtener cuentas afectadas por los países seleccionados
            CalculateAffectedAccounts(selectedCountries).then((response) => {
                if (response?.type === 1) {
                    setAffectedAccounts(response.data);
                }
            });
        } else {
            setAffectedAccounts(selectedAccountValues.length);
        }
    }, [selectedCountries, selectedAccountValues]); // Dependencias

    useEffect(() => {
        console.log("Cambios en la configuracion", selectedAccountValues)
    }, [selectedAccountValues])

    return (
        <div className="account-assignation-component">


            <Row className="commissions-configuration-list">
                <Col xs={24} md={24} className="commission-container" >
                    <Form form={form}
                        layout="vertical"
                        initialValues={{
                            isActive: props.configuration.isActive,
                            markets: props.configuration.marketsSelected.filter((selectedMarket) =>
                                props.markets.some((market) => market.key === selectedMarket)
                            ),
                            accounts: props.configuration.accountsSelected.map((account) => account.id), // Preselección de valores


                            from: props.configuration.applyFrom ? moment.utc(props.configuration.applyFrom,).local() : null,
                            to: props.configuration.applyTo ? moment.utc(props.configuration.applyTo,).local() : null,
                            contract: props.configuration.contractSelected,
                        }}
                        onFinish={(values) => {
                            handleSave(props.configuration, values);
                        }} // Manejar guardar
                    >

                        <Row gutter={[10, 0]}>
                            <Col xs={24} md={24} className="commission-title">
                                <div className="title-container">
                                    <h3>{props.configuration.name}</h3>

                                    <EditOutlined
                                        className="edit-icon-style"
                                        onClick={() => {
                                            props.setSelectedConfiguration(props.configuration); // Selecciona la configuración actual
                                            props.setEditDrawerVisible(true); // Abre el drawer de edición
                                        }}
                                    />
                                </div>

                                <Form.Item name="isActive" className="switch-container">
                                    <Switch
                                        className="app-switch"
                                        checked={props.configuration.isActive}
                                        disabled={isChangingStatus}
                                        onClick={() =>
                                            handleSwitchClick(props.configuration.isActive, props.configuration)
                                        }
                                    />
                                </Form.Item>
                            </Col>

                        </Row>


                        <Row gutter={[10, 0]}>
                            <Col xs={24} md={12}>
                                <Form.Item name="contract" label={t("pages.account-asignation.contract")}>
                                    <Select
                                        className="app-select"
                                        options={props.contracts.map((contract) => ({
                                            label: contract.value,
                                            value: contract.key,
                                        }))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} md={12}></Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="markets" label={t("pages.account-asignation.market")}>
                                    <Select
                                        className="app-select"
                                        mode="multiple"
                                        value={selectedCountries}
                                        onSelect={handleSelectCountry}
                                        onDeselect={handleDeselectCountry}
                                        options={props.markets.map((market) => ({
                                            label: market.value,
                                            value: market.key,
                                        }))
                                        }
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label as string).toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="accounts" label={t("pages.account-asignation.accounts")}>
                                    <Select
                                        className="app-select"
                                        loading={loading} // Indicador de carga
                                        showSearch
                                        mode="multiple"
                                        value={form.getFieldValue('accounts')} // Obtener el valor desde el formulario
                                        filterOption={false} // Desactiva el filtrado automático
                                        options={options} // Usar las opciones formateadas
                                        onSearch={handleSearch} // Buscar opciones dinámicas
                                        onSelect={handleChangeSelect} // Actualizar las opciones seleccionadas
                                        onDeselect={handleChangeDeselect} // Actualizar las opciones seleccionadas
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <div style={{ padding: "8px", textAlign: "center", fontStyle: "italic" }}>
                                                    {totalMatches > 0
                                                        ? `Hay ${totalMatches} coincidencias`
                                                        : "Sin coincidencias"}
                                                </div>
                                            </>
                                        )}
                                    />


                                </Form.Item>
                            </Col>


                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="from"
                                    label={t("pages.account-asignation.from")}
                                >
                                    <DatePicker className="app-input" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="to"
                                    label={t("pages.account-asignation.to")}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const passwordFieldValue = getFieldValue('from');
                                                if (passwordFieldValue && value && value.isBefore(passwordFieldValue)) {
                                                    return Promise.reject(t("pages.account-asignation.error-date-validation"));
                                                }
                                                return Promise.resolve();
                                            }
                                        }),
                                    ]}
                                >
                                    <DatePicker className="app-input" />
                                </Form.Item>
                            </Col>

                            {// en cada conflict tenemos key = id del agent web
                                //                                   value = id de la configuracion con la que tiene conflicto  

                                //props.accounts -> key = id del agent web value = nombre del agent web
                            }

                            <Col xs={24} md={12} className="feedback-container">
                                <h3 className="feedback-title-style">{t("pages.account-asignation.feedback")}</h3>
                                <div className="app-text xs">
                                    <strong>{t("pages.account-asignation.accounts-affected")} </strong>
                                    {affectedAccounts}
                                    { /* affectedAccounts[props.configuration.id] || 0 */}
                                </div>
                                { /*conflict && (
                                                    <>
                                                        <div className="app-text xs">
                                                            <strong>{t("pages.account-asignation.conflicts")}</strong>
                                                        </div>
                                                        {conflicts[props.configuration.id]?.map((conflict, index) => (
                                                            <div key={index}className="app-text xs">
                                                                {props.props.configurationData.find((ac) => ac.id === conflict.value)?.name || ''} : {' '}
                                                                {t("pages.account-asignation.conflict-description")}{' '}
                                                                {props.accounts.find((ac) => ac.key === conflict.key)?.value || ''}
                                                            </div>
                                                        ))}
                                                    </>
                                                )*/}
                            </Col>


                            <Col xs={24} md={12} className="buttons-container">

                                <Button type="primary"
                                    loading={saving}
                                    disabled={deleting || saving}
                                    htmlType="submit"
                                    className="app-button">
                                    {t("button.save")}
                                </Button>
                                <Button
                                    type="default"
                                    className="app-button danger"
                                    disabled={deleting || saving}
                                    loading={deleting} // Muestra el estado de carga específico
                                    onClick={() => setDeleteModalVisible(true)}
                                >
                                    {t("button.delete")}
                                </Button>

                            </Col>

                        </Row>
                    </Form>
                </Col>

            </Row>


            <GenericModal
                isLoading={isLoadingModal}
                title={t("pages.account-asignation.confirm-status-title")}
                closable
                content={<div>{t("pages.account-asignation.confirm-message")}</div>}
                visible={modalVisible}
                setModalVisible={setModalVisible}
                onOk={confirmSwitchChange}
                onCancel={cancelSwitchChange}
            />

            <GenericModal
                title={t("pages.account-asignation.confirm-delete-title")}
                closable
                content={<div>{t("pages.account-asignation.delete-message")}</div>}
                visible={deleteModalVisible}
                onOk={() => onDeleteConfiguration(props.configuration.id)}
                isLoading={deleting}
                setModalVisible={setDeleteModalVisible}
                onCancel={() => setDeleteModalVisible(false)}

            />


        </div >
    );
}

export default AccountAsignationElement;