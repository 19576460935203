import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'PaymentsConfiguration'

export const PaymentsConfigurationEP : EndPointConfig[] =[
     
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("CreatePaymentMethodConfiguration"),

        new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setActionName("DeletePaymentsConfiguration"),


        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetPaymentsMethodConfiguration"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("EditPaymentMethodConfiguration"),

       
        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetPaymentsMethods"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("CheckIsValidPaymentConfigurationName"),   
]