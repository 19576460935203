import { Button, Col, Row, Select, Form, DatePicker, Switch, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { AccountAsignationData, EditAccountAsignationData, KeyValue } from "../../../models/AccountAsignationData";
import { EditOutlined } from "@ant-design/icons";
import { LoadingData } from "../../common";
import './PaymentsMethodList.scss';
import { EditPaymentConfigurationData, PaymentConfigurationData } from "../../../models/PaymentMethodData";

import PaymentMethodElement from "../element/PaymentMethodElement";


interface PaymentsMethodListProps {
    configurations: PaymentConfigurationData[];

    markets : KeyValue[];
    paymentsMethods : KeyValue[];



    save: (data: EditPaymentConfigurationData) => void;
    edit: (data: EditPaymentConfigurationData) => void;
    delete: (id: string) => void;

    isLoading: boolean;

    setSelectedConfiguration: (aad: PaymentConfigurationData | null) => void;
    setEditDrawerVisible: (isVisible: boolean) => void;
}

const PaymentsMethodList = (props: PaymentsMethodListProps): JSX.Element => {

 

    return (
        <div>
            {props.isLoading && <LoadingData />}
            <Row className="payment-method-list">
                {!props.isLoading && (
                    <>
                        {props.configurations.map((pm) => (
                            <PaymentMethodElement 
                            configuration={pm}
                            save={props.save}
                            edit={props.edit}
                            delete={props.delete}
                            setEditDrawerVisible={props.setEditDrawerVisible}
                            setSelectedConfiguration={props.setSelectedConfiguration} // Pasamos esta función
                            markets = {props.markets}
                            paymentsMethods = {props.paymentsMethods}

                            />
                        ))}

                    </>
                )}


            </Row>


        </div >
    );
}

export default PaymentsMethodList;