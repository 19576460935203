import React from 'react';
import { Tag } from 'antd';
import { ClockCircleOutlined, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { newStatus, NewStatus } from '../../utils/constants';
import './TagStatusNews.scss';

// Definir el tipo NewStatus

interface StatusTagProps {
    status: NewStatus;
    sheduleDayFrom: Date;
}

const TagStatusNews: React.FC<StatusTagProps> = ({ status, sheduleDayFrom }) => {
    const { t } = useTranslation();

    const tagLabelSwitch = (val: NewStatus) => {
       
        const statusObj = newStatus.find((status) => status.key === val);
        return statusObj ? t(statusObj.label) : '';
    };

    // Función que devuelve el icono basado en el estado y la fecha
    const tagIconSwitch = (val: NewStatus | number, startDate: Date) => {
        switch (val) {
            case "active":
            case 0:
                return moment().isBefore(startDate) ? (
                    <ClockCircleOutlined className="icons-switch" />
                ) : (
                    <CheckCircleOutlined className="icons-switch" />
                );
            case "inactive":
            case 2:
                return <MinusCircleOutlined className="icons-switch" />;
            case "draft":
            case 1:
                return <ClockCircleOutlined className="icons-switch" />;
            default:
                return <></>;
        }
    };

    return (
        <Tag
            className="tag-status-news"
            icon={tagIconSwitch(status, sheduleDayFrom)}
            color={
                status === "active"
                    ? "success"
                    : status === "draft"
                    ? "default"
                    : "error"
            }
        >
            {tagLabelSwitch(status)}
        </Tag>
    );
};

export default TagStatusNews;
