import './NewsPage.scss';
import { useTranslation } from 'react-i18next';
import { AutoComplete, Button, Checkbox, Col, DatePicker, Divider, Dropdown, Form, Input, Menu, message, Row, Upload } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NewsData } from '../../../models/CommunicationsData';
import { ChangeNewsStatus, CreateNews, deleteNews, EditFeaturedNews, EditNews, fetchLanguagesAvailables, GetNewsData } from '../../../api/repositoryEP';
import { LoadingData } from '../../../components/common';
import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, LoadingOutlined, MoreOutlined, PlusOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import TagStatusNews from '../../../components/news/TagStatusNews';
import { NewStatus } from '../../../utils/constants';
import { Typography } from 'antd';
import moment from 'moment';
import { GenericModal } from '../../../components/common/Modal/GenericModal';
import LanguageSelectorNews from '../../../components/common/LanguageSelectorNews/LanguageSelectorNews';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import TinyEditor from '../../../components/common/Editor/TinyEditor';
import { useForm } from 'antd/es/form/Form';
import { useSessionData } from '../../../Contexts/SessionDataContext';


const NewsPage = (): JSX.Element => {
    const { t } = useTranslation();
    const appConfig = useAppConfig();
    const navigate = useNavigate();
    const [newsInfo, setNewsInfo] = useState<NewsData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [visibleModalDeleteNews, setVisibleModalDeleteNews] = useState(false);
    const [visibleModalUnpublishedNews, setVisibleModalUnpublishedNews] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>("");
    const [isVisibleDraftModal, setIsVisibleDraftModal] = useState<boolean>(false);
    const [isThereAnyChange, setIsThereAnyChange] = useState<boolean>(false);
    const [form] = useForm();
    const sessionData = useSessionData();
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [availableLanguages, setAvailableLanguages] = useState<string[]>([]);

    const { id } = useParams<{ id: string }>();
    const onEdit = !!id;  // Determina si estás en modo edición


    useEffect(() => {
        if (newsInfo && selectedLanguage) {
            const title = newsInfo.titlesByLanguage.find((lang) => lang.key === selectedLanguage)?.value || '';
            form.setFieldValue("title", title);
        }
    }, [newsInfo, selectedLanguage, form]);


    useEffect(() => {
        const initializePage = async () => {
            setIsLoading(true);

            try {
                // Fetch idiomas disponibles
                const languagesResponse = await fetchLanguagesAvailables("wa", false);
                const languages = languagesResponse?.type === 1 && Array.isArray(languagesResponse.data) ? languagesResponse.data : [];

                if (languages.length > 0) {
                    setAvailableLanguages(languages);
                    setSelectedLanguage(languages[0]); // Idioma inicial
                }

                // Fetch datos de la noticia
                if (onEdit) {
                    const response = await GetNewsData(id!);
                    const data: NewsData = response?.data;


                    if (data) {
                        setNewsInfo(data);
                        setImageForUpload(data.img)
                        const nameImage = data.img.split('/').pop();

           

                        // Asegúrate de que Moment sepa que la fecha es UTC
                        const localDateFrom = moment.utc(data.sheduleDayFrom, "YYYY-MM-DDTHH:mm:ss").local();
                        console.log(localDateFrom.format()); // Ver el formato
                        
                        const localDateTo = data.sheduleDayTo 
                            ? moment.utc(data.sheduleDayTo, "YYYY-MM-DDTHH:mm:ss").local() 
                            : null;
                        
                        console.log(localDateTo?.format()); // Ver el formato final
                        


              


                        // Establecer valores iniciales del formulario
                        form.setFieldsValue({
                            sheduledPublishDateFrom: localDateFrom,
                            sheduledPublishDateTo: localDateTo,
                            title: data.titlesByLanguage.find((lang) => lang.key === languages[0])?.value || '',
                            uploadFile: nameImage,
                        });
                    }
                } else {
                    form.setFieldsValue({
                        sheduledPublishDateFrom: moment().utc().local(),
                        sheduledPublishDateTo: null,
                        title: ''
                    });
                }
            } finally {
                setIsLoading(false);
            }
        };

        initializePage();
    }, [onEdit, form, id]);



    const onFinish = (values: any) => {
        setLoadingButton(true);
        if (!onEdit) {// crear
            const data: NewsData = {
                id: newsInfo!.id,
                descriptionsByLanguage: newsInfo!.descriptionsByLanguage,
                titlesByLanguage: newsInfo!.titlesByLanguage,
                isFeatured: values.isFeatureNew,
                sheduleDayFrom: values.sheduledPublishDateFrom || moment().utc(),
                sheduleDayTo: values.sheduledPublishDateTo,
                status: "active",
                img: fileObj,
                imgName: fileObj?.name,
                imgContentType: fileObj?.type,
                base64Image: imageForUpload!
            }

            CreateNews(data, sessionData.userData.id).then((r) => {
                if (r?.type === 1) {
                    setIsThereAnyChange(false); // para que no se muestre el modal de borrador al salir
                    navigate(getNewsPath());
                }
                setLoadingButton(false);
            })

        } else { // editar
            // al editar, si la foto ya está subida y no ha cambiado, 
            //entonces tenemos el nombre guardado en imgName y imgContentType y base64Image es nulo
            // sino , subimos la foto 

            const data: NewsData = {
                id: newsInfo!.id,
                descriptionsByLanguage: newsInfo!.descriptionsByLanguage,
                titlesByLanguage: newsInfo!.titlesByLanguage,
                isFeatured: values.isFeatureNew,
                sheduleDayFrom: values.sheduledPublishDateFrom || moment().utc(),
                sheduleDayTo: values.sheduledPublishDateTo,
                status: "active",
                img: fileObj,
                imgName: fileObj?.name ? fileObj.name : imageForUpload,
                imgContentType: fileObj?.type,
                base64Image: fileObj?.name ? imageForUpload! : null
            }

            EditNews(data).then((r) => {
                if (r?.type === 1) {
                    setIsThereAnyChange(false); // para que no se muestre el modal de borrador al salir
                    navigate(getNewsPath());
                }
                setLoadingButton(false);
            })

        }

    };

    const onSaveDraft = () => {
        setIsVisibleDraftModal(true);
    };
    const onCancel = () => {
        navigate(getNewsPath());
    };

    const handleNavigateBack = () => {
        if (!isThereAnyChange) {
            navigate(getNewsPath());
        } else {
            setIsVisibleDraftModal(true);
        }

    };

    const handleLanguageChange = (value: string) => {
        setSelectedLanguage(value);
    };


    const handleDescriptionChange = (content: string) => {
        setNewsInfo((prev) => {
            if (!prev) return null;

            // Verificar si descriptionsByLanguage ya contiene el idioma seleccionado
            const languageExists = prev.descriptionsByLanguage?.some(
                (lang) => lang.key === selectedLanguage
            );

            let updatedDescriptions;

            if (languageExists) {
                // Si existe, actualizar el valor correspondiente
                updatedDescriptions = prev.descriptionsByLanguage?.map((lang) =>
                    lang.key === selectedLanguage ? { ...lang, value: content } : lang
                );
            } else {
                // Si no existe, agregar un nuevo objeto para el idioma seleccionado
                updatedDescriptions = [
                    ...(prev.descriptionsByLanguage || []),
                    { key: selectedLanguage, value: content },
                ];
            }


            return { ...prev, descriptionsByLanguage: updatedDescriptions };
        });
    };


    const handleCheckboxChange = (checked: boolean) => {
        if (checked) {
            // Establece la fecha actual como valor por defecto
            form.setFieldsValue({ sheduledPublishDateFrom: moment().utc().local() });
        } else {
            form.setFieldsValue({ sheduledPublishDateFrom: null });
        }
    };
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);


    const changeNewStatus = async (id: string, isActive: boolean) => {
        setIsLoadingModal(true);
        try {
            const response = await ChangeNewsStatus(id, isActive);
            if (response?.type === 1 && response?.data === true) {
                setNewsInfo((prevNews: any) => {

                    if (prevNews) {
                        const sheduleDay = isActive ? moment().utc() : null;

                        form.setFieldValue("sheduledPublishDateFrom", sheduleDay);
                        form.setFieldValue("sheduledPublishDateTo", !isActive ? null : prevNews.sheduleDayTo);
                        return {
                            ...prevNews,
                            status: isActive ? "active" : "inactive",
                            sheduleDayFrom: sheduleDay,
                        };

                    }
                    return prevNews;
                });
            }

        } finally {
            //   setIsLoadingModal(false);
            setVisibleModalUnpublishedNews(false);
        }
    };


    const DeleteNews = async (id: string): Promise<void> => {
        const r = await deleteNews(id);
        if (r?.type === 1 && r?.data === true) {
            navigate(getNewsPath());
        }
    };


    const menu = (id: string, status: NewStatus) => (
        <Menu>
            <Menu.Item key="1" onClick={() => setVisibleModalDeleteNews(true)} icon={<DeleteOutlined />}>
                {t('components.communications.delete')}
            </Menu.Item>
            {status === "active" && (
                <Menu.Item key="4" onClick={() => setVisibleModalUnpublishedNews(true)} icon={<CloseCircleOutlined />}>
                    {t('components.communications.unpublish')}
                </Menu.Item>
            )}

        </Menu>
    );

    const ThreeDotsMenu = ({ id, status }: { id: string, status: NewStatus }) => (
        <Dropdown overlay={menu(id, status)} trigger={["click"]}>
            <Button type="text" icon={<MoreOutlined className="three-dots-icon" />} />
        </Dropdown>
    );


    const AllowedImagesType = "image/jpg,image/jpeg,image/png,image/svg";
    const MaxFileSizeMB = 2;
    const MinDimensions = { width: 300, height: 100 }; // Dimensiones mínimas
    const AspectRatioThreshold = 2.8; // Tolerancia de relación de aspecto



    const [imageForUpload, setImageForUpload] = useState<string | null>(null);
    const [fileObj, setFileObj] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const beforeUpload = (file: File) => {

        const isAllowedType = AllowedImagesType.includes(file.type.split("/")[1]);
        const isUnder2MB = file.size / 1024 / 1024 < MaxFileSizeMB;
        if (!isAllowedType) {
            setErrorMessage(t("components.communications.bad-image-format") || "");
            return false;
        } else if (!isUnder2MB) {
            setErrorMessage(t("components.communications.bad-image-size") || "");
            return false;
        } else {
            setErrorMessage("");
        }
        return true;
    };

    const validateImageDimensions = (
        file: File,
        callback: (isValid: boolean) => void
    ) => {
        const img = new Image();
        img.onload = () => {
            const width = img.width;
            const height = img.height;

            // Validación de dimensiones mínimas
            if (width < MinDimensions.width || height < MinDimensions.height) {
                setErrorMessage(
                    t("components.communications.form.min-dimmension") +
                    ` ${MinDimensions.width} x ${MinDimensions.height} px`
                );
                callback(false);
                return;
            }

            // Validación de relación de aspecto
            const aspectRatio = width / height;
            const isPanoramic =
                Math.abs(aspectRatio - 3) <= AspectRatioThreshold; // Relación 3:1
            if (!isPanoramic || height> width) {
                setErrorMessage(
                    t("components.communications.form.invalid-aspect-ratio") || ""
                );
                console.log("aspectRatio", aspectRatio);
                callback(false);
                return;
            }

            callback(true);
        };

        img.onerror = () => {
            setErrorMessage(
                t("components.communications.error-processing-img") || ""
            );
            callback(false);
        };

        img.src = URL.createObjectURL(file);
    };

    const handleImgChange = (info: any) => {
        const file: File = info.file.originFileObj;

        validateImageDimensions(file, (isValid) => {
            if (!isValid) return;

            const reader = new FileReader();
            reader.onload = (e) => {
                setImageForUpload(e.target?.result as string); // Guarda la imagen en base64
                setErrorMessage(""); // Limpiar errores al cargar correctamente
            };
            reader.readAsDataURL(file);

            setFileObj(file); // Guarda el archivo original
        });
    };



    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const title = event.target.value;
        setNewsInfo((prevNews) => {
            // Si no hay noticias previas, inicializamos el estado
            if (!prevNews) {
                return {
                    titlesByLanguage: [{ key: selectedLanguage, value: title }],
                } as NewsData;
            }

            // Verificamos si el idioma ya existe
            const languageExists = prevNews.titlesByLanguage.some(
                (language) => language.key === selectedLanguage
            );

            if (languageExists) {
                // Actualizamos el título del idioma existente
                return {
                    ...prevNews,
                    titlesByLanguage: prevNews.titlesByLanguage.map((language) =>
                        language.key === selectedLanguage
                            ? { ...language, value: title }
                            : language
                    ),
                };
            }

            // Si el idioma no existe, lo añadimos
            return {
                ...prevNews,
                titlesByLanguage: [
                    ...prevNews.titlesByLanguage,
                    { key: selectedLanguage, value: title },
                ],
            };
        });
    };

    const handleDraft = async (): Promise<void> => {
        setIsLoadingModal(true);
        setNewsInfo((prevNews: any) => {
            const updatedNews = {
                ...prevNews,
                titlesByLanguage: prevNews?.titlesByLanguage || [],
                descriptionsByLanguage: prevNews?.descriptionsByLanguage || [],
            };

            // Ahora ejecutamos la lógica con el estado actualizado
            const data: NewsData = {
                id: updatedNews.id || "",
                titlesByLanguage: updatedNews.titlesByLanguage,
                descriptionsByLanguage: updatedNews.descriptionsByLanguage,
                isFeatured: form.getFieldValue("isFeatureNew") || false,
                sheduleDayFrom: form.getFieldValue("sheduledPublishDateFrom"),
                sheduleDayTo: form.getFieldValue("sheduledPublishDateTo"),
                status: "draft",
                img: fileObj,
                imgName: fileObj?.name || "",
                imgContentType: fileObj?.type || "",
                base64Image: imageForUpload! || "",
            };

            processDraft(data); // Mover lógica aquí
            return updatedNews; // Esto asegura que React actualice correctamente
        });
    };

    const processDraft = async (data: NewsData): Promise<void> => {
        try {
            let r;
            if (!onEdit || id === null) {
                r = await CreateNews(data, sessionData.userData.id);
            } else {
                r = await EditNews(data);
            }

            if (r?.type === 1) {
                setIsThereAnyChange(false); // Para que no se muestre el modal de borrador al salir
                navigate(getNewsPath());
            }
        } finally {
            setIsLoadingModal(false);
            setIsVisibleDraftModal(false);
        }
    };



    const sheduledPublishDateFrom = (() => {
        if (!onEdit) {
            // Si es nuevo, la fecha por defecto es ahora
            return moment().utc();
        }
        if (newsInfo?.status === "inactive") {
            // Si no está activo, el valor es nulo
            return null;
        }
        // Devuelve la fecha programada desde `newsInfo`

        return moment.utc(newsInfo?.sheduleDayFrom).local();
    })();

    const handleValuesChange = () => {
        if (!isThereAnyChange && (newsInfo?.titlesByLanguage != undefined)) { // Solo se ejecuta si aún no ha cambiado y si al menos hay un título
            setIsThereAnyChange(true);
        }
    };

    const getNewsPath = () => {
        if (onEdit) {
            return window.location.pathname.replace(`/new/${id}`, ""); // Elimina "new/:id"
        } else {
            return window.location.pathname.replace(`/new`, ""); // Elimina "new"
        }
    };



    return (
        <div className="news-page">
            {isLoading && <LoadingData />}
            {!isLoading && (
                <>
                    <Form layout='vertical'
                        onFinish={onFinish}
                        form={form}
                        onValuesChange={handleValuesChange} // Captura cambios una sola vez
                        initialValues={{
                            sheduledPublishDateFrom: sheduledPublishDateFrom,
                        }}>
                        <Row gutter={[16, 0]}>
                            <GenericModal
                                isLoading={isLoadingModal && visibleModalUnpublishedNews}
                                title={t("components.communications.unpublish-new")}
                                visible={visibleModalUnpublishedNews}
                                setModalVisible={setVisibleModalUnpublishedNews}
                                onCancel={() => {
                                    setVisibleModalUnpublishedNews(false);  // Cierra el modal
                                }}
                                content={<div>{t("components.communications.unpublish-new-message")}</div>}
                                onOk={() => newsInfo && changeNewStatus(newsInfo.id, false)} />
                            <GenericModal
                                isLoading={isLoadingModal && visibleModalDeleteNews}
                                title={t("components.communications.delete-new")}
                                visible={visibleModalDeleteNews}
                                setModalVisible={setVisibleModalDeleteNews}
                                onCancel={() => {
                                    setVisibleModalDeleteNews(false); // Cierra el modal
                                }}
                                content={<div>{t("components.communications.delete-new-message")}</div>}
                                onOk={async () => {
                                    if (newsInfo) {
                                        try {
                                            setIsLoadingModal(true); // Muestra el estado de carga
                                            await DeleteNews(newsInfo.id); // Espera a que se complete la operación
                                            setVisibleModalDeleteNews(false); // Cierra el modal
                                        } catch (error) {
                                            console.error("Error deleting news:", error); // Manejo de errores
                                        } finally {
                                            setIsLoadingModal(false); // Restablece el estado de carga
                                        }
                                    }
                                }}
                            />

                            <GenericModal
                                title={t("components.communications.draft-new")}
                                visible={isVisibleDraftModal && isVisibleDraftModal}
                                setModalVisible={setIsVisibleDraftModal}
                                isLoading={isLoadingModal}
                                onCancel={() => {
                                    setIsVisibleDraftModal(false); // Cierra el modal
                                    navigate(getNewsPath());
                                }}
                                content={<div>{t("components.communications.draft-new-message")}</div>}
                                onOk={async () => {
                                    try {
                                        await handleDraft(); // Espera a que se complete el draft

                                    } finally {


                                    }
                                }}
                            />

                            <Col xs={24} md={24}>
                                <span
                                    className="app-text back-link"
                                    onClick={handleNavigateBack}
                                >
                                    <ArrowLeftOutlined className="back-button" />
                                    {t("button.back-to-news")}
                                </span>
                            </Col>

                            {onEdit && newsInfo && (
                                <Col xs={24} md={24} className="container-right">
                                    <span>
                                        <TagStatusNews
                                            status={newsInfo.status}
                                            sheduleDayFrom={newsInfo.sheduleDayFrom} />
                                        {newsInfo.isFeatured ? (
                                            <StarFilled
                                                onClick={() =>
                                                    EditFeaturedNews(newsInfo.id, false).then((res) => {
                                                        if (res?.type === 1) {
                                                            setNewsInfo((prevNewsInfo: any) => ({
                                                                ...prevNewsInfo,
                                                                isFeatured: false,
                                                            }));
                                                        }
                                                    })
                                                }
                                                className="featured-icon"
                                            />
                                        ) : (
                                            <StarOutlined
                                                onClick={() =>
                                                    EditFeaturedNews(newsInfo.id, true).then((res) => {
                                                        if (res?.type === 1) {
                                                            setNewsInfo((prevNewsInfo: any) => ({
                                                                ...prevNewsInfo,
                                                                isFeatured: true,
                                                            }));
                                                        }
                                                    })
                                                }
                                                className="featured-icon"
                                            />
                                        )}

                                        <ThreeDotsMenu id={newsInfo.id} status={newsInfo.status} />
                                    </span>
                                </Col>
                            )}

                            {newsInfo?.status === "active" && (
                                <Col xs={24} md={24} className='container-right'>
                                    <Typography.Paragraph className='paragraph-style'>  {t("components.communications.news-will-be-publish")} </Typography.Paragraph>
                                    <Typography.Paragraph className='paragraph-style'> {moment(newsInfo?.sheduleDayFrom).utc().format('DD/MM/YYYY')}  </Typography.Paragraph>
                                </Col>
                            )}

                        </Row>
                        <Row gutter={[16, 32]}  >
                            <Col xs={24} md={8}>
                                <Form.Item
                                    name="uploadFile"
                                    rules={[{ required: true, message: t("components.communications.form.image-required") ?? "" }]}
                                >

                                    <Upload
                                        accept={AllowedImagesType}
                                        name="uploadFile"
                                        listType="picture"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        beforeUpload={beforeUpload}
                                        onChange={handleImgChange}
                                    >
                                        {imageForUpload ? (
                                            <span className="upload-container">
                                                <img
                                                    src={imageForUpload}
                                                    alt="upload"
                                                />
                                                <DeleteOutlined
                                                    className="delete-icon"
                                                    onClick={() => setImageForUpload("")}
                                                />
                                            </span>
                                        ) : (
                                            <div className="upload-container upload-placeholder">
                                                <PlusOutlined />
                                                <div >{t("components.communications.upload-image")}</div>
                                            </div>
                                        )}

                                    </Upload>
                                </Form.Item>
                                <Divider />
                                {errorMessage && (
                                    <div className="image-error">
                                        {errorMessage}
                                    </div>
                                )}
                                <div className='image-recommendations'>
                                    {t("components.communications.image-recommendations-size")}
                                </div>
                                <div className='image-recommendations'>
                                    {t("components.communications.image-recommendations")}
                                </div>
                            </Col>

                            <Col xs={24} md={16} >
                                <Row gutter={[0, 0]} className='border-container'>
                                    <Col xs={24} md={24} className="app-title large">
                                        <Form.Item
                                            label={t("components.communications.form.language")}
                                            name="language">
                                            <LanguageSelectorNews languages={availableLanguages}
                                                selectedLanguage={selectedLanguage}
                                                onLanguageChange={handleLanguageChange} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={24} className="app-title large" >
                                        <Form.Item
                                            label={t("components.communications.form.title")}
                                            name="title"
                                            rules={[
                                                {
                                                    validator: () => {
                                                        if (
                                                            !newsInfo?.titlesByLanguage ||
                                                            newsInfo.titlesByLanguage.every((item) => !item.value.trim())
                                                        ) {
                                                            return Promise.reject(t("components.communications.form.title-required"));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                value={
                                                    newsInfo?.titlesByLanguage.find((lang) => lang.key === selectedLanguage)?.value || ''
                                                }
                                                onChange={handleTitleChange}
                                            />
                                        </Form.Item>

                                    </Col>
                                    <Col xs={24} md={24}  >
                                        <Form.Item
                                            label={t("components.communications.form.text")}
                                            name="text"
                                            rules={[
                                                {
                                                    validator: () => {
                                                        if (
                                                            !newsInfo?.descriptionsByLanguage ||
                                                            newsInfo.descriptionsByLanguage.every((item) => !item.value.trim())
                                                        ) {
                                                            return Promise.reject(t("components.communications.form.description-required"));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <TinyEditor
                                                content={newsInfo?.descriptionsByLanguage?.find((lang) => lang.key === selectedLanguage)?.value || ''}
                                                setContent={handleDescriptionChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="border-container">
                                    <Col xs={24} md={14} className="checkbox-col">
                                        <Form.Item name="isSheduledPublishDate" valuePropName="checked">
                                            <div className="checkbox-with-description">
                                                <Checkbox
                                                    defaultChecked={moment(newsInfo?.sheduleDayFrom).isAfter(moment().utc())}
                                                    onChange={(e) => handleCheckboxChange(e.target.checked)}
                                                >
                                                    {t("components.communications.form.is-sheduled-publish-date")}
                                                </Checkbox>
                                                <span className="description">
                                                    {t("components.communications.form.is-sheduled-publish-date-description")}
                                                </span>
                                            </div>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={10}>
                                        <Row gutter={[0, 0]} style={{ width: '100%', margin: 0 }}>
                                            <Col xs={24} md={24} className="date-picker-col">
                                                <Form.Item shouldUpdate>
                                                    {({ getFieldValue }) => (
                                                        <Form.Item
                                                            label={t("components.communications.form.sheduled-publish-date-from")}
                                                            name="sheduledPublishDateFrom"
                                                        >
                                                            <DatePicker
                                                                format="YYYY-MM-DD"
                                                                disabled={!getFieldValue("isSheduledPublishDate")}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={24} className="date-picker-col">
                                                <Form.Item
                                                    label={t("components.communications.form.sheduled-publish-date-to")}
                                                    name="sheduledPublishDateTo"
                                                    dependencies={["sheduledPublishDateFrom"]}
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const fromDate = getFieldValue("sheduledPublishDateFrom");
                                                                if (value && fromDate && value.isBefore(fromDate)) {
                                                                    return Promise.reject(
                                                                        t("components.communications.form.sheduled-publish-date-to-error") || ""
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <DatePicker format="YYYY-MM-DD" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>


                                {!onEdit && (
                                    <Row gutter={[0, 0]} className='border-container'>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="isFeatureNew" valuePropName="checked" >
                                                <div className="checkbox-with-description">
                                                    <Checkbox>{t("components.communications.form.is-featured-new")}</Checkbox>
                                                    <span className="description">
                                                        {t("components.communications.form.is-featured-new-description")}
                                                    </span>
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                <Row gutter={[16, 16]} justify="end">


                                    <Col xs={24} md={8} xl={6}>
                                        <Button className="app-cancel-button large"
                                            onClick={onCancel}>
                                            {t("button.cancel")}
                                        </Button>
                                    </Col>

                                    {// solo tendremos un borrador si no estamos editando o si estamos editando y la noticia no está activa
                                    }
                                    {(!onEdit || (onEdit && newsInfo?.status != "active")) && (
                                        <Col xs={24} md={8} xl={6}>
                                            <Button className="app-button reverse full" disabled={!isThereAnyChange}
                                                onClick={onSaveDraft}>
                                                {t("button.save-drift")}
                                            </Button>
                                        </Col>
                                    )}
                                    <Col xs={24} md={8} xl={6}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={loadingButton}
                                                className="app-button full"
                                            >
                                                {loadingButton ? (
                                                    <>
                                                        <LoadingOutlined /> {t((onEdit && newsInfo?.status != "draft") ? "button.editing" : "button.publishing")}
                                                    </>
                                                ) : (
                                                    t((onEdit && newsInfo?.status != "draft") ? "button.edit" : "button.publish")
                                                )}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}
        </div>
    );
};

export default NewsPage;
