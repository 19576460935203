import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'News'

export const NewsEP : EndPointConfig[] =[
        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetNews"),

        new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setActionName("DeleteNews"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("EditFeaturedNews"),
        
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("ChangeNewsStatus"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetNewsDataBO"),   

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("CreateNews"),   

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("EditNews"),   
]