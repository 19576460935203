import { Alert, Button, Col, Form, Input, Row, Select } from "antd";

import './AddContractComponent.scss';
import { useTranslation } from "react-i18next";
import CreateContract from "../../../models/CreateContract";
import { useEffect, useState } from "react";

interface AddContractComponentProps {
    onFinish: (contractData: CreateContract) => void;
    onCancel: () => void;
    resetFields: number;
    loading: boolean;
}

const AddContractComponent = (props: AddContractComponentProps) : JSX.Element => {
    const { t } = useTranslation();
    
    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(props.loading);

    useEffect(()=>{
        setLoading(props.loading);
    }
    ,[props.loading]);

    const { onFinish, onCancel, resetFields } = props;

    useEffect(() => {
        form.resetFields();
    },[resetFields]);

    const onCancelAdd = () : void => {
        form.resetFields();
        onCancel();
    }

    const onAddContract = () : void => {

        let contract : CreateContract = {
            name: form.getFieldValue('name'),
            pmsCode: form.getFieldValue('pmsCode') ?? '',
            integrationCode: form.getFieldValue('integrationCode'),
            isBase: isBaseContract,
            isActive: isActive
        }

        onFinish(contract);
    }

    const [isActive, setIsActive] = useState<boolean>(true);
    const [isBaseContract, setIsBaseContract] = useState<boolean>(false);

    return (
        <div className="add-contract">
            <Form form={form} layout="vertical" onFinish={onAddContract} >
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item
                            name="name" 
                            label={t("components.contract-form-add.name")} 
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input className="app-input" allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item 
                            name="pmsCode" 
                            label={t("components.contract-form-add.pms-code")}
                        >
                            <Input className="app-input" allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item 
                            name="integrationCode" 
                            label={t("components.contract-form-add.integration-code")} 
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >

                            <Input className="app-input" allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="isBaseContract" label={t("components.contract-form-add.is-base-contract")} initialValue={false}>
                            <Select onChange={setIsBaseContract} className="app-input" defaultValue={false} allowClear>
                                <Select.Option value={true}>{t("components.contract-form-add.is-base-contract-yes")}</Select.Option>
                                <Select.Option value={false}>{t("components.contract-form-add.is-base-contract-no")}</Select.Option>                                
                            </Select>
                        </Form.Item>
                        {
                            //If isBaseContract == true then show the next message:

                            isBaseContract && <Alert className="alert-base-message" message={t("components.contract-form-add.is-base-contract-message")} type="warning" />
                        }
                    </Col>
                    <Col xs={24}>
                        <Form.Item name="isActive" label={t("components.contract-form-add.is-active")} initialValue={true}>
                        <Select onChange={setIsActive} className="app-input" allowClear>
                                <Select.Option value={true}>{t("components.contract-form-add.is-active-yes")}</Select.Option>
                                <Select.Option value={false}>{t("components.contract-form-add.is-active-no")}</Select.Option>                                
                            </Select>
                        </Form.Item>
                    </Col>
                    <div className="actions">
                        <Button disabled={loading} className="app-button danger" type="primary" onClick={onCancelAdd}>
                            {t("components.contract-form-add.cancel")}
                        </Button>
                        <Button disabled={loading} className="app-button" type="primary" htmlType="submit">
                            {
                                loading ? <div className="app-spinner"></div> : null
                            } {t("components.contract-form-add.save")}
                        </Button>
                    </div>
                </Row>
            </Form>
        </div>
    )
}

export default AddContractComponent;