

import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Countries'

export const CountriesEP : EndPointConfig[] =[
        

       
        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetCountries"),

        

        
]