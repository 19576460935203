
import { useTranslation } from "react-i18next";
import { ConfigurationsFilter } from "../../../models/AccountAsignationData";
import Search from "antd/es/input/Search";

interface AccountAsignationFilterComponentProps {
   // accountAsignationData: AccountAsignationData[];

    setActualFilter: (filter: ConfigurationsFilter) => void;
    actualFilter: ConfigurationsFilter;


}

const AccountAsignationFilterComponent = (props: AccountAsignationFilterComponentProps): JSX.Element => {
    const { t } = useTranslation();



    return (
        <div>
            <Search
                className="app-search"
                allowClear 
                placeholder={t("button.search") ||""} 
                onSearch={(value) => props.setActualFilter({ ...props.actualFilter, name: value })}
             />

           
        </div>
    );
}

export default AccountAsignationFilterComponent;