import { Menu, MenuProps } from "antd";
import { useNavigate } from "react-router-dom";


import './PrivateMenuComponent.scss'
import { GetFormattedUrl } from "../../../utils/urls";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { LanguageSelector } from "..";
import { useTranslation } from "react-i18next";
import React from "react";
import { GetAccountsIcon, GetBookingsIcon, GetCommissionsIcon, GetCommunicationsIcon, GetConfigIcon, GetContractsIcon, GetCreditCardsIcon, GetGroupIcon, GetHouseIcon, GetStatisticsIcon } from "../../../utils/icons";

interface PrivateMenuComponentProps {
  onSelectItem: (key: string) => void;
}

const PrivateMenuComponent = (props: PrivateMenuComponentProps) : JSX.Element => {
    const navigate = useNavigate();
    const appConfig = useAppConfig();
    type MenuItem = Required<MenuProps>['items'][number];

    const { t } = useTranslation();

    function getItem(
      label: React.ReactNode,
      key: React.Key,
      icon?: React.ReactNode,
      disabled?: boolean,
      children?: MenuItem[],
      type?: 'group'
    ): MenuItem {
      return {
        key,
        icon,
        children,
        label,
        type,
        disabled
      } as MenuItem;
    }

    const expandedIcons : MenuItem[] = [
      getItem('', 'language-selector', <LanguageSelector />),
      { type: 'divider' }
    ];

    const itemsForTabletAndDesktop: MenuItem[] = [
        getItem(t('menus.private.home'), 'dashboard', GetHouseIcon("app-home-icon")),
        getItem(t('menus.private.accounts-admin'), 'accounts-admin', GetAccountsIcon("app-home-icon")),
        getItem(t('menus.private.bookings-admin'), 'bookings-admin', GetBookingsIcon("app-home-icon")),
        getItem(t('menus.private.statistics'), 'statistics', GetStatisticsIcon("app-home-icon disabled"), true),
        getItem(t('menus.private.communications'), 'communications', GetCommunicationsIcon("app-home-icon")),
        getItem(t('menus.private.contracts'), 'contracts', GetContractsIcon("app-home-icon")),
        getItem(t('menus.private.commissions'), 'commissions', GetCommissionsIcon("app-home-icon")),       
        getItem(t('menus.private.payments-config'), 'payments-config', GetCreditCardsIcon("app-home-icon")),
        getItem(t('menus.private.configuration'), 'config/pro-configuration', GetConfigIcon("app-home-icon")),
        getItem(t('menus.private.users'), 'users', GetGroupIcon("app-home-icon")),
    ];

    const itemsForMobile: MenuItem[] = [
      ...expandedIcons,
      ...itemsForTabletAndDesktop
    ];

    const goPage = (page: string) : void => {
        navigate(`${GetFormattedUrl(`private/${page}`)}`);
    }

    const [selectedKey, setSelectedKey] = React.useState<string>('dashboard');

    //Get the last part of the url to set the selected menu item
    React.useEffect(() => {
      const url = window.location.href;
      const lastPart = url.split('/').pop();
      if (lastPart) {

        //El caso de las páginas de configuración tienen un tratamiento especial porque la url contiene más de un nivel 
        //y o se hace esto o el menú no se queda marcado correctamente.
        const configPages = ['pro-configuration', 'landing-page', 'register-form', 'group-form', 'mapping-config'];
        if (configPages.indexOf(lastPart) > -1){
          setSelectedKey(`config/pro-configuration`);
        }else{
          setSelectedKey(lastPart);
        }
      }
    }, [window.location.href]);

    return(<div className="private-menu">
            <Menu
                  className="menu"
                  defaultSelectedKeys={['dashboard']}
                  selectedKeys={[selectedKey]}
                  defaultOpenKeys={['sub1']}
                  mode={appConfig.device === "mobile" ? "inline" : "vertical"}
                  items={appConfig.device === "mobile" ? itemsForMobile : itemsForTabletAndDesktop}
                  onClick={(e : any)=>{
                    if (e.key !== 'currency-selector' && e.key !== 'language-selector'){
                      setSelectedKey(e.key);
                      props.onSelectItem(e.key);
                      goPage(e.key)
                    }
                  }}
              />
          </div>)
}

export default PrivateMenuComponent;

