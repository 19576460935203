import { Editor } from '@tinymce/tinymce-react';
import { useSessionData } from '../../../Contexts/SessionDataContext';

interface TinyEditorProps {
  content: string;
  setContent: (content: string) => void;
}

const TinyEditor = ( props : TinyEditorProps) => {
  const useUserData = useSessionData();
  
  return (
    <Editor
      apiKey='l85gt2wufrhxg3vn2nhvz3nwru0at7o39z36y0ub3ylbz37q'
      init={{
        language: useUserData.userData.defaultLanguage.slice(-2).toLowerCase(),
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',  tinycomments_mode: 'embedded',
      }}
      initialValue={props.content}
      onChange={(e) => props.setContent(e.target.getContent())}
      
    />
  );
}

export default TinyEditor;