import { Button, Col, Row, Select, Form, DatePicker, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { KeyValue } from "../../../models/AccountAsignationData";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import './PaymentsMethodElement.scss';
import { useCallback, useEffect, useState } from "react";
import { GetConfigurationAccounts } from "../../../api/repositoryEP";
import debounce from "lodash/debounce";
import { EditPaymentConfigurationData, PaymentConfigurationData } from "../../../models/PaymentMethodData";
import { GenericKeyValuePair } from "../../../models/CustomField";
import { GenericModal } from "../../common/Modal/GenericModal";



interface PaymentMethodElementProps {
    configuration: PaymentConfigurationData;
    setEditDrawerVisible: (isVisible: boolean) => void;
    setSelectedConfiguration: (c: PaymentConfigurationData | null) => void; // Nueva prop

    markets: KeyValue[];
    paymentsMethods: KeyValue[];


    save: (data: EditPaymentConfigurationData) => void;
    edit: (data: EditPaymentConfigurationData) => void;
    delete: (id: string) => void;


}

const PaymentMethodElement = (props: PaymentMethodElementProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [deleting, setIsDeleting] = useState<boolean>(false);
    const [saving, setIsSaving] = useState<boolean>(false);
    const [isChangingStatus, setIsChangingStatus] = useState<boolean>(false);

    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);


    const [loading, setLoading] = useState<boolean>(false);
    const [totalMatches, setTotalMatches] = useState<number>(0); // Total de coincidencias en la búsequeda
    const [options, setOptions] = useState(
        props.configuration?.accountsSelected?.map((account) => ({
            label: account.name, // Mostrar el 'value' como etiqueta
            value: account.id,  // Usar el 'key' como valor interno
            country: account.country, // O la propiedad específica que tengas para el país
            accountType: account.accountType
        }))
    );


    const [selectedAccountValues, setSelectedAccountValues] = useState(
        props.configuration.accountsSelected?.map((account) => ({
            id: account.id,
            name: account.name,
            accountType: account.accountType,
            country: account.country, // O la propiedad específica que tengas para el país

        }))
    );

    const [selectedCountries, setSelectedCountries] = useState<string[]>(props.configuration.marketsSelected || []); // Cantidad de opciones seleccionadas






    const onDeleteConfiguration = async (id: string) => {
        setIsDeleting(true);
        try {
            await props.delete(id); // Llama a la función de eliminación

        } finally {
            setIsDeleting(false);
        }
    };

    const handleSave = async (values: any) => {
        setIsSaving(true)

        var accountsData = values.accounts.map((accountId: string) => {
            var accountType = selectedAccountValues.find((accountValue) => accountValue.id === accountId)?.accountType;
            var data: GenericKeyValuePair = {
                key: accountId,
                value: accountType
            }

            return data;
        })


        const updatedAccount: EditPaymentConfigurationData = {
            id: props.configuration.id,
            name: null,
            isActive: null,
            marketsSelected: values.markets, // Ya contiene los keys seleccionados
            accountsSelected: accountsData, // Ya contiene los keys seleccionados
            paymentsMethodSelected: values.paymentsMethod, // Directamente el key seleccionado
        };

        try {
            await props.save(updatedAccount);
        } finally {
            // Desactiva el estado de carga para este ID
            setIsSaving(false)
        }
    };


    /////////////////////////////////// SWITCH ///////////////////////////////////
    const [currentSwitchValue, setCurrentSwitchValue] = useState<boolean>(props.configuration.isActive);
    const handleSwitchClick = async (currentChecked: boolean) => {

        const updatedAccount: EditPaymentConfigurationData = {
            id: props.configuration.id,
            name: null,
            isActive: currentChecked,
            marketsSelected: null, // Ya contiene los keys seleccionados
            accountsSelected: null, // Ya contiene los keys seleccionados
            paymentsMethodSelected: null, // Directamente el key seleccionado
        };


        try {
            setIsChangingStatus(true)
            const r = await props.edit(updatedAccount);

            setCurrentSwitchValue(currentChecked);

        } finally {
            // Desactiva el estado de carga para este ID
            setIsChangingStatus(false)
        }
    };

    /////////////////////////////////// SWITCH ///////////////////////////////////






    const handleChangeSelect = (values: string[]) => {
        console.log("options: ", options);
        console.log("values: ", values);

        // Obtener las opciones seleccionadas basadas en los nuevos valores
        const updatedSelected = options?.filter(opt => values.includes(opt.value));

        // Combinar las cuentas previamente seleccionadas con las nuevas, sin duplicados
        const combinedSelected = [
            ...(selectedAccountValues || []),
            ...(updatedSelected ?? []).map(opt => ({
                id: opt.value,
                name: opt.label,
                country: opt.country,
                accountType: opt.accountType
            })),
        ].filter((value, index, self) =>
            index === self.findIndex(v => v.id === value.id) // Eliminar duplicados por ID
        );

        // Actualizar el estado local de selectedAccountValues
        setSelectedAccountValues(combinedSelected);

        // Actualizar el campo del formulario con los IDs seleccionados
        form.setFieldValue(
            'accounts',
            combinedSelected.map(account => account.id)
        );
    };

    const handleChangeDeselect = (value: string) => {
        console.log("Deseleccionado:", value);

        // Filtrar la cuenta eliminada del estado
        const updatedSelected = selectedAccountValues?.filter(account => account.id !== value);
        setSelectedAccountValues(updatedSelected);

        // Actualizar el formulario con los IDs restantes
        form.setFieldValue(
            'accounts',
            updatedSelected?.map(account => account.id)
        );

        setOptions(options?.filter(option => option.value !== value));
    };



    const handleSearch = useCallback(
        debounce(async (name) => {

            if (name) {
                setLoading(true);
                try {
                    const response = await GetConfigurationAccounts(selectedCountries, name);
                    if (response?.type === 1) {
                        console.log("respuesta : ", response.data.accounts)
                        // Formatear las opciones y excluir seleccionados
                        const formattedOptions = response.data.accounts
                            .map((account: any) => ({
                                label: <div className="account-info"><label>{account.name} </label><small>{account.actualContractName}</small><small>{account.address} ({account.country})</small></div>, // Nombre del account
                                value: account.id,
                                address: account.address,
                                accountType: account.accountType,
                                actualContractName: account.actualContractName,
                                country: account.country
                            }))
                            .filter((option: any) => !selectedAccountValues?.includes(option.value)); // Excluir seleccionados

                        // Actualizar las opciones sin acumular duplicados
                        setOptions(formattedOptions);
                        setTotalMatches(formattedOptions.length);
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                setOptions([]); // Limpia si no hay búsqueda
                setTotalMatches(0);
            }
        }, 300),
        [selectedAccountValues, selectedCountries]
    );





    useEffect(() => {
        // Sincronizar el campo del formulario con los IDs de las cuentas seleccionadas
        form.setFieldValue(
            'accounts',
            selectedAccountValues?.map(account => account.id)
        );
    }, [selectedAccountValues, form]);



    useEffect(() => {
        return () => handleSearch.cancel();
    }, [handleSearch]);





    ////////////////////////////////////////////////// COUNTRIES ////////////////////////////////////////////

    const handleSelectCountry = (value: string) => {
        // Agregar el país a la lista de países seleccionados
        setSelectedCountries((prevSelected) => [...prevSelected, value]);

        // Filtrar las cuentas seleccionadas que correspondan al país actual o a cualquier país en selectedCountries
        setSelectedAccountValues((prevSelected) =>
            (prevSelected || []).filter(
                (account) =>
                    account.country === value || selectedCountries.includes(account.country)
            )
        );
    };

    const handleDeselectCountry = (value: string) => {
        // 1. Eliminar el país de la lista de países seleccionados
        setSelectedCountries((prevSelected) =>
            prevSelected.filter((country) => country !== value)
        );

        // 2. Actualizar las cuentas seleccionadas para eliminar las asociadas al país deseleccionado
        setSelectedAccountValues((prevSelected) =>
            (prevSelected || []).filter((account) =>
                selectedCountries.filter((country) => country !== value).includes(account.country)
            )
        );
    };
    /////////////////////////////////////////////////////////////////////////////////////////////



    return (
        <div className="payments-configuration-element">
            <Row >
                <Col xs={24} md={24} className="configuration-container" >
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            isActive: props.configuration.isActive,
                            markets: props.configuration.marketsSelected,
                            accounts: props.configuration.accountsSelected?.map((account) => account.id), // Preselección de valores
                            paymentsMethod: props.configuration.paymentsMethodSelected,
                        }}
                        onFinish={(values) => {
                            handleSave(values);
                        }} // Manejar guardar
                    >

                        <Col xs={24} md={24} className="commission-title">
                            <div className="title-container">
                                <h3>{props.configuration.name}</h3>

                                <EditOutlined
                                    className="edit-icon-style"
                                    onClick={() => {
                                        props.setSelectedConfiguration(props.configuration); // Selecciona la configuración actual
                                        props.setEditDrawerVisible(true); // Abre el drawer de edición
                                    }}
                                />

                            </div>

                            <Form.Item name="isActive" className="switch-container">
                                <Switch
                                    className="app-switch"
                                    checked={currentSwitchValue}
                                    disabled={isChangingStatus}
                                    onClick={(value) =>
                                        handleSwitchClick(value)
                                    }
                                />
                            </Form.Item>
                        </Col>


                        <Row gutter={[10, 0]}>
                            <Col xs={24} md={12}>
                                <Form.Item name="markets" label={t("pages.account-asignation.market")}>
                                    <Select
                                        className="app-select"
                                        mode="multiple"
                                        value={selectedCountries}
                                        onSelect={handleSelectCountry}
                                        onDeselect={handleDeselectCountry}
                                        options={props.markets.map((market) => ({
                                            label: market.value,
                                            value: market.key,
                                        }))}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label as string).toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="accounts" label={t("pages.account-asignation.accounts")}>
                                    <Select
                                        className="app-select"
                                        loading={loading} // Indicador de carga
                                        showSearch
                                        mode="multiple"
                                        value={form.getFieldValue("accounts")} // Mantiene las opciones seleccionadas
                                        filterOption={false} // Desactiva el filtrado automático
                                        options={options} // Usar las opciones formateadas
                                        onSearch={handleSearch} // Buscar opciones dinámicas
                                        onSelect={handleChangeSelect} // Actualizar las opciones seleccionadas
                                        onDeselect={handleChangeDeselect} // Actualizar las opciones seleccionadas
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <div style={{ padding: "8px", textAlign: "center", fontStyle: "italic" }}>
                                                    {totalMatches > 0
                                                        ? `Hay ${totalMatches} coincidencia(s)`
                                                        : "Sin coincidencias"}
                                                </div>
                                            </>
                                        )}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item name="paymentsMethod" label={t("pages.payments-configuration.payments-method")}>
                                    <Select
                                        className="app-select"
                                        mode="multiple"
                                        options={props.paymentsMethods?.map((pm) => ({
                                            label: pm.value,
                                            value: pm.key,
                                        })) || []}
                                    />
                                </Form.Item>
                            </Col>


                            <Col xs={24} md={12} className="buttons-container">
                                <Button type="primary"
                                    loading={saving}
                                    disabled={deleting || saving}
                                    htmlType="submit"
                                    className="app-button">
                                    {t("button.save")}
                                </Button>
                                <Button
                                    type="default"
                                    className="app-button danger"
                                    disabled={deleting || saving}
                                    loading={deleting} // Muestra el estado de carga específico
                                    onClick={() => setDeleteModalVisible(true)}>
                                    {t("button.delete")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <GenericModal
                title={t("pages.account-asignation.confirm-delete-title")}
                closable
                content={<div>{t("pages.account-asignation.delete-message")}</div>}
                visible={deleteModalVisible}
                onOk={() => onDeleteConfiguration(props.configuration.id)}
                isLoading={deleting}
                setModalVisible={setDeleteModalVisible}
                onCancel={() => setDeleteModalVisible(false)}
            />
        </div >
    );
}

export default PaymentMethodElement;